import React from "react";
import InputUpperLabel from "../Input/Label/InputUpper";

const EmptyForm = ({ upperLabel = undefined, children }) => {
    return (
        <div>
            {upperLabel && <InputUpperLabel>{upperLabel}</InputUpperLabel>}
            <div className="ring-1 ring-inset ring-gray-300 rounded-md">
                {children}
            </div>

        </div>
    )
}

export default EmptyForm;
