
import React from "react";
import { Routes, Route } from "react-router-dom";

import RealtimePage from "pages/Trading/Realtime";
import VisionPage from "pages/Trading/Vision";
import StatusPage from "pages/Trading/Status";
import ResultPage from "pages/Trading/Result";
import StatisticsPage from "pages/Trading/Statistics";
import ValuePage from "pages/Trading/Value";
import SpreadPage from "pages/Trading/Spread";

export default () => (
    <Routes>
        <Route path="/realtime" element={<RealtimePage />}></Route>
        <Route path="/vision" element={<VisionPage />}></Route>
        <Route path="/status" element={<StatusPage />}></Route>
        <Route path="/result" element={<ResultPage />}></Route>
        <Route path="/statistics" element={<StatisticsPage />}></Route>
        <Route path="/value" element={<ValuePage />}></Route>
        <Route path="/spread" element={<SpreadPage />}></Route>
    </Routes>
)

