import _ from 'lodash';
import axios from 'axios';
import exchangeConfigs from 'apis/exchange/config'
import momentTz from 'moment-timezone';
import ExchangeApi from 'apis/exchange';

export const timestampToDtString = (dt) => momentTz(dt).tz("Asia/Seoul").format().replace("T", " ").replace("+09:00", "");

export const resolutionToMsec = (resolution) => {
    if (resolution === "1D") {
        return 24 * 60 * 60 * 1000;
    } else if (resolution === "1W") {
        return 24 * 60 * 60 * 1000;
    } else if (resolution === "1M") {
        return 31 * 24 * 60 * 60 * 1000;
    } else {
        return parseInt(resolution) * 60 * 1000;
    }
}

const lastBarsCache = new Map();

const TV_PROXY_URL = "https://rest.defimix.io/tv";

const CustomDatafeed = {
    onReady: async (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback({}));
    },

    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
        console.log('[searchSymbols]: Method call');
        const exchangeApi = new ExchangeApi(exchange);
        await exchangeApi.init();
    },

    resolveSymbol: async (symbolWithExchange, onSymbolResolvedCallback, onResolveErrorCallback) => {
        console.log('[resolveSymbol]: Method call', symbolWithExchange);
        const exchangeName = symbolWithExchange.split(":")[0];
        const symbolName = symbolWithExchange.split(":")[1];
        const supportedResolution = ['1', '3', '5', '10', '15', '30', '45', '60', '90', '120', '180', '240', '1D']
        if (exchangeConfigs[exchangeName]?.hasWeekly) {
            supportedResolution.push('1W')
        }
        if (exchangeConfigs[exchangeName]?.hasMonthly) {
            supportedResolution.push('1M')
        }
        setTimeout(() => onSymbolResolvedCallback({
            ticker: symbolWithExchange,
            name: symbolName, //It's the name of the symbol. It is a string that your users will be able to see. Also, it will be used for data requests if you are not using tickers.
            description: symbolName, //Description of a symbol. Will be displayed in the chart legend for this symbol.
            type: 'crypto', //Optional type of the instrument.
            session: '24x7', //Trading hours for this symbol. See the Trading Sessions article to learn more details.
            timezone: 'Asia/Seoul', //Timezone of the exchange for this symbol. We expect to get the name of the time zone in olsondb format.
            exchange: exchangeName,
            minmov: 1, //More..
            pricescale: 100000,//More..
            has_intraday: true, //Default false, Boolean value showing whether the symbol includes intraday (minutes) historical data. If it's false then all buttons for intraday resolutions will be disabled for this particular symbol. If it is set to true, all resolutions that are supplied directly by the datafeed must be provided in intraday_multipliers array.
            visible_plots_set: 'ohlcv',
            has_weekly_and_monthly: true, // Default false, The boolean value showing whether data feed has its own weekly and monthly resolution bars or not. If has_weekly_and_monthly = false then Charting Library will build the respective resolutions using daily bars by itself. If not, then it will request those bars from the data feed.
            supported_resolutions: supportedResolution, //An array of resolutions which should be enabled for this symbol. More..
            volume_precision: 4,
            data_status: 'streaming', //streaming, endofday, pulsed, delayed_streaming, The status code of a series with this symbol. The status is shown in the upper right corner of a chart.
            intraday_multipliers: exchangeConfigs[exchangeName]?.intradayMultipliers, //Default [], Array of resolutions (in minutes) supported directly by the data feed. Each such resolution may be passed to, and should be implemented by, getBars. The default of [] means that the data feed supports aggregating by any number of minutes. More..
            has_daily: true, // Default false, The boolean value showing whether data feed has its own daily resolution bars or not. If has_daily = false then Charting Library will build the respective resolutions using 1-minute bars by itself. If not, then it will request those bars from the data feed.
        }));
    },

    getBars: async (tickerInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, countBack } = periodParams;
        console.log('[getBars]: Method call', timestampToDtString(from * 1000), timestampToDtString(to * 1000), countBack);
        const tickerName = tickerInfo.name;
        const exchangeName = tickerInfo.exchange;
        const exchangeApi = new ExchangeApi(exchangeName);
        const cacheKey = `${exchangeName}:${tickerName}:${resolution}`;
        const isFirstRequest = periodParams.firstDataRequest;
        const barLimit = exchangeConfigs[exchangeName].barLimit;
        let bars = [];
        const fromMsec = from * 1000;
        const toMsec = to * 1000;
        let requestToMsec = toMsec;
        for (let i = 0; i < 10; i++) {
            console.log("[Request Count]", i)
            console.log("[Request]", timestampToDtString(from * 1000), timestampToDtString(requestToMsec), barLimit)
            const requestFromMsec = Math.max(requestToMsec - barLimit * resolutionToMsec(resolution), fromMsec)
            const newBars = await exchangeApi.getBars(tickerName, requestFromMsec, requestToMsec, barLimit, resolution)
            if (newBars.length === 0) {
                break;
            }
            const firstBarTimeMsec = newBars[0].time;
            const lastBarTimeMsec = newBars[newBars.length - 1].time;
            console.log("[Received]", timestampToDtString(firstBarTimeMsec), timestampToDtString(lastBarTimeMsec), newBars.length)
            if (_.isEmpty(bars)) {
                bars = [...newBars]
            } else {
                const filteredNewBar = newBars.filter((bar) => bar.time < bars[0].time);
                bars = [...filteredNewBar, ...bars];
            }
            if (newBars.length < barLimit) {
                break;
            }
            if (firstBarTimeMsec < from * 1000) {
                console.log("[Get Bar] Satisfy From Range Condition]")
                break
            } else {
                if (requestToMsec === firstBarTimeMsec) {
                    console.log("[Get Bar] Same Data Received")
                    break;
                }
                requestToMsec = firstBarTimeMsec;
            };
        }
        const filteredBars = bars.sort((barA, barB) => barA - barB).filter((bar) => (bar.time / 1000) >= from && (bar.time / 1000) <= to);
        if (isFirstRequest) {
            // console.log("SET LAST BAR", cacheKey, { ...filteredBars[filteredBars.length - 1] })
            lastBarsCache.set(cacheKey, { ...filteredBars[filteredBars.length - 1] });
        }
        const noData = _.isEmpty(filteredBars);
        console.log(`[getBars]: returned ${filteredBars.length} bar(s)`);
        // console.log(lastBarsCache)
        onHistoryCallback(filteredBars, { noData });
    },
    lastBarsCache
};

export default CustomDatafeed;
