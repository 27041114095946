import { ILevel } from "./interface";

export const updateLevels = (orders: ILevel[], changes: [string, string][], isAsk: boolean): ILevel[] => {
    let updatedOrders = [...orders];

    changes.forEach(([priceStr, sizeStr]) => {
        const price = parseFloat(priceStr);
        const size = parseFloat(sizeStr);

        const index = updatedOrders.findIndex(order => order[0] === price);

        if (size === 0) {
            // Remove order if size is 0
            if (index !== -1) {
                updatedOrders.splice(index, 1);
            }
        } else {
            if (index !== -1) {
                // Update size if order exists
                updatedOrders[index][1] = size;
            } else {
                // Add new order
                updatedOrders.push([price, size]);
            }
        }
    });

    // Sort updated orders
    return updatedOrders.sort((a, b) => (isAsk ? a[0] - b[0] : b[0] - a[0]));
}

export const calculateAssetRates = (depth)=>{
    const assetNames = Object.keys(depth.upbit).map((ticker) => ticker.split("/")[0]);
    const rates = assetNames.map((assetName) => {
        if (assetName === "USDT") {
            const upbitBestBid = depth.upbit["USDT/KRW"].bids[0][0];
            const upbitBestAsk = depth.upbit["USDT/KRW"].asks[0][0];
            return ({
                asset: assetName,
                bid: upbitBestBid,
                mid: (upbitBestBid + upbitBestAsk) / 2,
                ask: upbitBestAsk
            })
        }
        const bybitTicker = `${assetName}/USDT`;
        const upbitTicker = `${assetName}/KRW`;
        const bybitBestBid = depth.bybit[bybitTicker].bids[0][0];
        const bybitBestAsk = depth.bybit[bybitTicker].asks[0][0];
        const upbitBestBid = depth.upbit[upbitTicker].bids[0][0];
        const upbitBestAsk = depth.upbit[upbitTicker].asks[0][0];

        return ({
            asset: assetName,
            bid: upbitBestBid / bybitBestAsk,
            mid: (upbitBestBid / bybitBestAsk + upbitBestAsk / bybitBestBid) / 2,
            ask: upbitBestAsk / bybitBestBid
        })
    })
    const usdtIndex = rates.findIndex((rate) => rate.asset === "USDT");
    const usdtRate = rates[usdtIndex];
    rates.splice(usdtIndex, 1);
    rates.unshift(usdtRate);
    return rates;
}