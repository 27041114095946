import { formatNumber } from "components/Financial/Depth";
import { Column } from "components/Table/Simple";
import { formatDate } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "_id",
        label: "ID",
        visible: false,
    },
    {
        field: "date",
        label: "Date",
        render: formatDate
    },
    {
        field: "profit",
        label: "Profit",
        render: formatNumber,
        align: "right"
    },
    {
        field: "makingProfit",
        label: "Making Profit",
        render: formatNumber,
        align: "right"
    },
    {
        field: "closingProfit",
        label: "Closing Profit",
        render: (value, field, row) => formatNumber(value, 2),
        align: "right"
    }
]