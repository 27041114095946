import { Column } from "components/Table/Simple"
import { LineWidth } from "lightweight-charts"

export const askLineOptions = {
    color: 'red',
    lineWidth: 2 as LineWidth
}

export const bidLineOptions = {
    color: 'green',
    lineWidth: 2 as LineWidth
}

export const mirrorAskLineOptions = {
    color: 'pink',
    lineWidth: 2 as LineWidth
}

export const mirrorBidLineOptions = {
    color: 'lightgreen',
    lineWidth: 2 as LineWidth
}

export const bybitOrdersTableColumn: Column[] = [
    {
        field: "symbol",
        label: "Symbol",
        visible: true
    },
    {
        field: "side",
        label: "Side",
        visible: true
    },
    {
        field: "price",
        label: "Price",
        visible: true
    },
    {
        field: "qty",
        label: "Qty",
        visible: true
    },
    {
        field: "orderLinkId",
        label: "Order Link Id",
        visible: true
    },
    {
        field: "orderId",
        label: "Order ID",
        visible: true
    },
    {
        field: "avgPrice",
        label: "Avg Price",
        visible: false
    },
    {
        field: "cumExecQty",
        label: "Cum Exec Qty",
        visible: false
    },
    {
        field: "cumExecValue",
        label: "Cum Exec Value",
        visible: false
    },
    {
        field: "cumExecFee",
        label: "Cum Exec Fee",
        visible: false
    },
    {
        field: "leavesQty",
        label: "Leaves Qty",
        visible: false
    },
    {
        field: "leavesValue",
        label: "Leaves Value",
        visible: false
    },
    {
        field: "orderStatus",
        label: "Order Status",
        visible: false
    },
    {
        field: "orderType",
        label: "Order Type",
        visible: false
    },
    {
        field: "createType",
        label: "Create Type",
        visible: false
    },
    {
        field: "timeInForce",
        label: "Time In Force",
        visible: false
    },
    {
        field: "stopOrderType",
        label: "Stop Order Type",
        visible: false
    },
    {
        field: "triggerDirection",
        label: "Trigger Direction",
        visible: false
    },
    {
        field: "triggerPrice",
        label: "Trigger Price",
        visible: false
    },
    {
        field: "tpLimitPrice",
        label: "TP Limit Price",
        visible: false
    },
    {
        field: "tpTriggerBy",
        label: "TP Trigger By",
        visible: false
    },
    {
        field: "takeProfit",
        label: "Take Profit",
        visible: false
    },
    {
        field: "stopLoss",
        label: "Stop Loss",
        visible: false
    },
    {
        field: "slTriggerBy",
        label: "SL Trigger By",
        visible: false
    },
    {
        field: "slLimitPrice",
        label: "SL Limit Price",
        visible: false
    },
    {
        field: "smpType",
        label: "SMP Type",
        visible: false
    },
    {
        field: "smpGroup",
        label: "SMP Group",
        visible: false
    },
    {
        field: "smpOrderId",
        label: "SMP Order ID",
        visible: false
    },
    {
        field: "blockTradeId",
        label: "Block Trade ID",
        visible: false
    },
    {
        field: "isLeverage",
        label: "Is Leverage",
        visible: false
    },
    {
        field: "rejectReason",
        label: "Reject Reason",
        visible: false
    },
    {
        field: "closeOnTrigger",
        label: "Close On Trigger",
        visible: false
    },
    {
        field: "reduceOnly",
        label: "Reduce Only",
        visible: false
    },
    {
        field: "placeType",
        label: "Place Type",
        visible: false
    },
    {
        field: "marketUnit",
        label: "Market Unit",
        visible: false
    },
    {
        field: "positionIdx",
        label: "Position Idx",
        visible: false
    },
    {
        field: "createdTime",
        label: "Created Time",
        visible: false
    },
    {
        field: "updatedTime",
        label: "Updated Time",
        visible: false
    },
    {
        field: "orderIv",
        label: "Order Iv",
        visible: false
    },
    {
        field: "tpslMode",
        label: "TPSL Mode",
        visible: false
    },
    {
        field: "triggerBy",
        label: "Trigger By",
        visible: false
    },
    {
        field: "cancelType",
        label: "Cancel Type",
        visible: false
    },
    {
        field: "lastPriceOnCreated",
        label: "Last Price On Created",
        visible: false
    }
]

/*
{
    "uuid": "af6f3527-ae25-4abb-aaf7-0d242f0d4f03",
    "side": "ask",
    "ord_type": "limit",
    "price": "153640000",
    "state": "done",
    "market": "KRW-BTC",
    "created_at": "2025-01-18T09:21:18+09:00",
    "volume": "0.01",
    "remaining_volume": "0",
    "reserved_fee": "0",
    "remaining_fee": "0",
    "paid_fee": "768.2",
    "locked": "0",
    "executed_volume": "0.01",
    "executed_funds": "1536400",
    "trades_count": 1,
    "time_in_force": "fok",
    "identifier": "L1_BTC_d06f4b6b14_eddb"
}
*/

export const upbitOrdersTableColumn: Column[] = [
    {
        field: "market",
        label: "Market",
        visible: true
    },
    {
        field: "side",
        label: "Side",
        visible: true
    },
    {
        field: "price",
        label: "Price",
        visible: true
    },
    {
        field: "identifier",
        label: "Identifier",
        visible: true
    },
    {
        field: "uuid",
        label: "UUID",
        visible: true
    },
    {
        field: "ord_type",
        label: "Order Type",
        visible: false
    },
    {
        field: "state",
        label: "State",
        visible: true
    },

    {
        field: "created_at",
        label: "Created At",
        visible: true
    },
    {
        field: "volume",
        label: "Volume",
        visible: true
    },
    {
        field: "remaining_volume",
        label: "Remaining Volume",
        visible: true
    },
    {
        field: "reserved_fee",
        label: "Reserved Fee",
        visible: true
    },
    {
        field: "remaining_fee",
        label: "Remaining Fee",
        visible: true
    },
    {
        field: "paid_fee",
        label: "Paid Fee",
        visible: true
    },
    {
        field: "locked",
        label: "Locked",
        visible: true
    },
    {
        field: "executed_volume",
        label: "Executed Volume",
        visible: true
    },
    {
        field: "executed_funds",
        label: "Executed Funds",
        visible: true
    },
    {
        field: "trades_count",
        label: "Trades Count",
        visible: true
    },
    {
        field: "time_in_force",
        label: "Time In Force",
        visible: true
    },
]