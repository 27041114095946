import React, { useEffect, useRef, useState } from 'react';

import { IChartApi, createChart } from 'lightweight-charts';

export const EXAMPLE_LW_CHART_DATA = [
    { time: '2019-04-11', value: 80.01 },
    { time: '2019-04-12', value: 96.63 },
    { time: '2019-04-13', value: 76.64 },
    { time: '2019-04-14', value: 81.89 },
    { time: '2019-04-15', value: 74.43 },
    { time: '2019-04-16', value: 80.01 },
    { time: '2019-04-17', value: 96.63 },
    { time: '2019-04-18', value: 76.64 },
    { time: '2019-04-19', value: 81.89 },
    { time: '2019-04-20', value: 74.43 },
];

const LwChart = ({ onChartReady = (chartHandler: IChartApi) => { } }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = createChart(chartRef.current, {
            width: chartRef?.current?.offsetWidth,
            height: Math.min(chartRef?.current?.offsetWidth / 1.414, 400),
            autoSize: true,
            localization: {
                dateFormat: 'yyyy-MM-dd',
            },
        });
        // const lineSeries = chart.addLineSeries();
        // lineSeries.setData(exampleData);
        onChartReady(chart);
    }, [])

    return (
        <div>
            <div ref={chartRef}></div>
        </div >
    )
}

export default LwChart;
