import korbit from './korbit';
import bithumb from './bithumb';
import upbit from './upbit';
import coinone from './coinone';
import gopax from './gopax';
import binance from './binance';
import gateio from './gateio';
import mexc from './mexc';
import bybit from './bybit';
import kucoin from './kucoin';
import okx from './okx';
import bitget from './bitget';
import kraken from './kraken';

import binance_usdm from './binance_usdm';

import { IExchangeConfig } from './interface';

const exchangeConfigs: { [exchangeName: string]: IExchangeConfig } = {
    "Korbit": korbit,
    "Bithumb": bithumb,
    "Upbit": upbit,
    "Coinone": coinone,
    "Gopax": gopax,
    "Binance": binance,
    "Gate.io": gateio,
    "MEXC": mexc,
    "Bybit": bybit,
    "KuCoin": kucoin,
    "OKX": okx,
    "Bitget": bitget,
    "Kraken": kraken,
    "Binance_USDM": binance_usdm,
}

export default exchangeConfigs;
