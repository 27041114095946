import axios from "axios";
import Card from "components/Card";
import LwChart from "components/Chart/LwChart";
import PageTitle from "components/Title/Page";
import { IChartApi } from "lightweight-charts";
import _ from "lodash";
import React, { useEffect, useState } from "react";

const ValuePage = () => {
    const [chartHandler, setChartHandler] = useState<IChartApi>(null);

    useEffect(() => {
        if (_.isNil(chartHandler)) {
            return;
        }
        console.log("Chart Ready", chartHandler);
        fetchData();
    }, [chartHandler])

    const fetchData = async ()=>{
        const ret = await axios.get("https://rest.cefimix.com/stat/balance");
        console.log(ret.data);
        const chartData = ret.data.map((item)=>{
            return {
                time: new Date(item.createdAt).getTime(),
                value: item.totalValue
            }
        })
        chartData.sort((a, b) => a.time - b.time);
        const series = chartHandler.addLineSeries({
            color: 'black',
            lineWidth: 2,
        });
        series.setData(chartData);
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Total Value</PageTitle>
                <LwChart onChartReady={setChartHandler} />
            </div>
        </Card>
    )
}

export default ValuePage;