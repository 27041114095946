import _ from "lodash";
import { IDepth, IExchangeConfig } from "apis/exchange/interface";
import { updateLevels } from "../helper";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1',
    '3': '3',
    '5': '5',
    '15': '15',
    '30': '30',
    '60': '60',
    '120': '120',
    '240': '240',
    '360': '360',
    '720': '720',
    '1D': 'D',
    '1W': 'W',
    '1M': 'M'
}

const CATEGORY = "linear"; // spot, linear

const BybitConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/bybit",

    wsEndpoint: `wss://stream.bybit.com/v5/public/${CATEGORY}`,

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: `/v5/market/instruments-info?category=${CATEGORY}&limit=1000`,

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] bybit`, rawSymbolsData.result.list)
        const symbolRet = rawSymbolsData?.result?.list;
        if (_.isNil(symbolRet)) {
            throw { msg: "bybit Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        symbolRet.forEach((elm) => { // krw market
            const { baseCoin, quoteCoin, symbol } = elm;
            const ticker = `${baseCoin}/${quoteCoin}`;
            const exchangeSymbol = symbol;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        // console.log(`[Parse Symbols] bybit`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] bybit`, symbol, resolution)
        return `/v5/market/kline`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] bybit`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            category: CATEGORY,
            symbol: symbol,
            interval: resolutionMap[resolution],
            start: requestFromMsec,
            end: requestToMsec,
            limit: limit,
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        console.log(`[Parse Historical Bar] bybit`, rawBarData)
        const barRet = rawBarData?.result?.list;
        let bars = [];
        if (_.isNil(barRet)) {
            throw { msg: "bybit Parse Bars Error" }
        }
        barRet.forEach(elm => {
            bars = [...bars, {
                time: Number(elm[0]),
                open: Number(elm[1]),
                high: Number(elm[2]),
                low: Number(elm[3]),
                close: Number(elm[4]),
                volume: Number(elm[5])
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        const { b, a } = rawDepthData.data;

        const bids = b.map(([price, size]: [string, string]) => [parseFloat(price), parseFloat(size)]);
        const asks = a.map(([price, size]: [string, string]) => [parseFloat(price), parseFloat(size)]);

        return {
            bids: bids.sort((a, b) => b[0] - a[0]), // Descending for bids
            asks: asks.sort((a, b) => a[0] - b[0]), // Ascending for asks
        };
    },

    getDepthSubscribeMsg: function (symbol) {
        return this.getMultiDepthSubscribeMsg([symbol]);
    },

    getMultiDepthSubscribeMsg: function (symbols) {
        return {
            op: "subscribe",
            args: symbols.map(symbol => `orderbook.50.${symbol}`)
        }
    },

    getDepthUnsubscribeMsg: function (symbol) {
        return this.getMultiDepthUnsubscribeMsg([symbol]);
    },

    getMultiDepthUnsubscribeMsg: function (symbols) {
        return {
            op: "unsubscribe",
            args: symbols.map(symbol => `orderbook.50.${symbol}`)
        }
    },

    parseDepthTick: function (prevDepth: IDepth, msgObj: any) {
        const msg = msgObj;
        if (msg.type === "snapshot") {
            const newDepth = this.parseFullDepth(msg);
            return newDepth;
        } else if (msg.type === "delta") {
            const { b, a } = msg.data;
            const updatedBids = updateLevels(prevDepth.bids, b, false);
            const updatedAsks = updateLevels(prevDepth.asks, a, true);
            return {
                bids: updatedBids,
                asks: updatedAsks,
            };
        } else {
            return { status: "otherMsg", ...prevDepth };
        }
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] bybit`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol)
        return {
            req_id: "td", // optional
            op: "subscribe",
            args: [
                `publicTrade.${symbol}`,
            ]
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol)
        return {
            req_id: "td", // optional
            op: "unsubscribe",
            args: [
                `publicTrade.${symbol}`,
            ]
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] bybit`, rawMsgObj)
        const tradeRet = rawMsgObj?.data;
        if (_.isNil(tradeRet)) {
            return { status: "other" };
        }
        const tradeArr = tradeRet.map((tick) => {
            const tradeTime = Number(tick.T);
            const tradePrice = Number(tick.p);
            const tradeSize = Number(tick.v);
            return { tradeTime, tradePrice, tradeSize }
        })
        return { tradeArr, status: "array" }
    },

    decodeWsMsg: (rawMsg) => {
        return JSON.parse(rawMsg.data);
    },

    parseSymbolFromWsMsg: (rawMsg) => {
        return rawMsg?.data?.s;
    }
}

export default BybitConfig;