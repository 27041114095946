import axios from "axios";
import Card from "components/Card";
import SimpleTable from "components/Table/Simple";
import PageTitle from "components/Title/Page";
import React, { useEffect, useState } from "react";
import { columns, ratesTableColumns } from "./config";
import Select, { SelectItem } from "components/Form/Select";
import _, { set } from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import Button from "components/Form/Button";
import { useModal } from "components/Modal/Simple";
import { calculateAssetRates } from "apis/exchange/helper";

const data = [
    {
      uuid: '489c4aa3-9f0c-4cf5-a14d-02e3d072ad66',
      side: 'ask',
      ord_type: 'limit',
      price: '540400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:59:11+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '810.6',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1621200',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_68747c6914_clear_b850'
    },
    {
      uuid: '90176034-a913-4bdf-8ae0-77ceb47f8aba',
      side: 'bid',
      ord_type: 'limit',
      price: '541400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:57:20+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '812.1',
      remaining_fee: '0',
      paid_fee: '812.1',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1624200',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_68747c6914_1802'
    },
    {
      uuid: 'fe8bcd2e-e0a0-444d-819c-e7d6a9165f69',
      side: 'bid',
      ord_type: 'limit',
      price: '539000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:57:06+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '808.5',
      remaining_fee: '0',
      paid_fee: '808.5',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1617000',
      trades_count: 1,
      identifier: 'L1_AAVE_62b68fc822_clear_e52b'
    },
    {
      uuid: '62f8bbdd-2b5b-419b-ba32-9aa01cf02354',
      side: 'bid',
      ord_type: 'limit',
      price: '538000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:54:35+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '807',
      remaining_fee: '0',
      paid_fee: '807',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1614000',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_f5291bd432_3df3'
    },
    {
      uuid: '8818d509-be1d-4954-96b2-26b0235cc03c',
      side: 'ask',
      ord_type: 'limit',
      price: '537200',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:53:49+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '805.8',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1611600',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_c19d38af28_clear_27c0'
    },
    {
      uuid: 'b0046d76-5b1f-46eb-968a-3de2d36fb85f',
      side: 'bid',
      ord_type: 'limit',
      price: '538000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:52:38+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '807',
      remaining_fee: '0',
      paid_fee: '807',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1614000',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_c7ccd7b327_29ef'
    },
    {
      uuid: 'ea56d55d-632e-4e38-9d04-6149f8cd6e6b',
      side: 'bid',
      ord_type: 'limit',
      price: '538900',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:48:42+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '808.35',
      remaining_fee: '0',
      paid_fee: '808.35',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1616700',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_c19d38af28_4dee'
    },
    {
      uuid: 'fab871b3-5c44-41d0-80a3-7ea0878436cb',
      side: 'bid',
      ord_type: 'limit',
      price: '540600',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:43:06+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '810.9',
      remaining_fee: '0',
      paid_fee: '810.9',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1621800',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'L2_AAVE_f09fec4623_clear_c439'
    },
    {
      uuid: 'aa20ebd0-d94a-4dd4-b97d-00b45cbeb2ee',
      side: 'ask',
      ord_type: 'limit',
      price: '539400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:42:14+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '809.1',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1618200',
      trades_count: 3,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_62f7f7df26_clear_685f'
    },
    {
      uuid: '4c5cc676-d8e0-47ca-b180-cfced4f194f5',
      side: 'ask',
      ord_type: 'limit',
      price: '539300',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:41:18+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '808.95',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1617900',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'L2_AAVE_f09fec4623_a8e1'
    },
    {
      uuid: '45b5d90f-1cf6-4503-9196-8a5fbf8c4699',
      side: 'ask',
      ord_type: 'limit',
      price: '539400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:37:19+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '809.1',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1618200',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_9d13645916_clear_be71'
    },
    {
      uuid: '76528399-c2b8-457e-8086-c393541ab55d',
      side: 'bid',
      ord_type: 'limit',
      price: '540500',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:36:55+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '810.75',
      remaining_fee: '0',
      paid_fee: '810.75',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1621500',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_9d13645916_64f7'
    },
    {
      uuid: 'c9278aac-cf82-4a21-af41-c56805cc84ac',
      side: 'bid',
      ord_type: 'limit',
      price: '539800',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:36:29+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '809.7',
      remaining_fee: '0',
      paid_fee: '809.7',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1619400',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_62f7f7df26_1f14'
    },
    {
      uuid: '7dbd8714-7456-48e7-b238-1e9b64de8ac7',
      side: 'ask',
      ord_type: 'limit',
      price: '541100',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:33:41+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '811.65',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1623300',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'L1_AAVE_62b68fc822_662a'
    },
    {
      uuid: 'f2f0b62c-ed78-40e2-9821-0915bd33d3e7',
      side: 'ask',
      ord_type: 'limit',
      price: '539600',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:33:07+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '809.4',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1618800',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_462713f721_clear_5c50'
    },
    {
      uuid: '1d968f4e-629b-4877-86a6-76cd4ffa3ccb',
      side: 'bid',
      ord_type: 'limit',
      price: '540000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:31:49+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '810',
      remaining_fee: '0',
      paid_fee: '810',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1620000',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_462713f721_40ac'
    },
    {
      uuid: '9aa0dbf6-bc8e-4928-81b2-7455fd9ff732',
      side: 'bid',
      ord_type: 'limit',
      price: '538900',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:30:51+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '808.35',
      remaining_fee: '0',
      paid_fee: '808.35',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1616700',
      trades_count: 4,
      time_in_force: 'fok',
      identifier: 'L2_AAVE_4c5889c47_clear_58a5'
    },
    {
      uuid: '7722b94a-607e-4ce3-805a-4f9871556032',
      side: 'bid',
      ord_type: 'limit',
      price: '538800',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:30:47+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '808.2',
      remaining_fee: '0',
      paid_fee: '808.2',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1616400',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'L1_AAVE_4ca468ed12_clear_c994'
    },
    {
      uuid: 'fa7fdf95-7b26-4beb-843f-57d6b82bb8de',
      side: 'ask',
      ord_type: 'limit',
      price: '537700',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:27:01+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '806.55',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1613100',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'L2_AAVE_4c5889c47_1f0b'
    },
    {
      uuid: '65616c15-b511-42aa-8e6f-ea1e76617523',
      side: 'ask',
      ord_type: 'limit',
      price: '538400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:26:50+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '807.6',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1615200',
      trades_count: 4,
      identifier: 'S1_AAVE_243de9586_clear_4746'
    },
    {
      uuid: 'dc6d9df6-b3d5-4039-ba5d-90af072fcc48',
      side: 'ask',
      ord_type: 'limit',
      price: '537900',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:23:53+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '806.85',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1613700',
      trades_count: 3,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_b28afe2415_clear_c16e'
    },
    {
      uuid: 'cae95844-56ee-48f2-a723-d3c212e95cc4',
      side: 'ask',
      ord_type: 'limit',
      price: '536700',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:18:49+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '805.05',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1610100',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'L1_AAVE_4ca468ed12_f9d7'
    },
    {
      uuid: '8dd0c255-78d6-4ebf-9d84-ee466b2140ce',
      side: 'bid',
      ord_type: 'limit',
      price: '537000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:12:19+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '805.5',
      remaining_fee: '0.75',
      paid_fee: '804.75',
      locked: '1500.75',
      executed_volume: '3',
      executed_funds: '1609500',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_b28afe2415_ab39'
    },
    {
      uuid: '9a3380bc-a240-4f59-8cec-4fb39265f58e',
      side: 'ask',
      ord_type: 'limit',
      price: '535300',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:09:55+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '802.95',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1605900',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_94e4fae113_clear_0f56'
    },
    {
      uuid: '212ae342-cfa7-486b-b43f-44bda8a92f90',
      side: 'ask',
      ord_type: 'limit',
      price: '535400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:08:21+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '803.1',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1606200',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_649548ec5_clear_2f01'
    },
    {
      uuid: '24c5a0b6-b7bf-4f73-95dd-99a968ddc318',
      side: 'bid',
      ord_type: 'limit',
      price: '536500',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:07:53+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '804.75',
      remaining_fee: '0',
      paid_fee: '804.75',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1609500',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_649548ec5_3896'
    },
    {
      uuid: '132deaca-12b9-438e-88c9-05e6750ae50c',
      side: 'bid',
      ord_type: 'limit',
      price: '536500',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:07:53+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '804.75',
      remaining_fee: '0',
      paid_fee: '804.75',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1609500',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_94e4fae113_ed90'
    },
    {
      uuid: '8c6d166a-4c6f-4f6e-8b82-863df2688c31',
      side: 'ask',
      ord_type: 'limit',
      price: '535000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:06:57+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '802.5',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1605000',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_0d9a8cd210_clear_f1c6'
    },
    {
      uuid: '2530eceb-772c-4e65-899a-68e88296b045',
      side: 'bid',
      ord_type: 'limit',
      price: '536600',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:06:57+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '804.9',
      remaining_fee: '0',
      paid_fee: '804.9',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1609800',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'L1_AAVE_436768a88_clear_fde2'
    },
    {
      uuid: 'eb97ef0a-22ab-4ad8-90f2-66af1ce687b0',
      side: 'bid',
      ord_type: 'limit',
      price: '536400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:05:39+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '804.6',
      remaining_fee: '0',
      paid_fee: '804.6',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1609200',
      trades_count: 4,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_0d9a8cd210_8186'
    },
    {
      uuid: '75822fe3-ed1f-49d3-9a5e-1a3dc0179bb2',
      side: 'ask',
      ord_type: 'limit',
      price: '534000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:01:45+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '801',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1602000',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_c38720424_clear_e460'
    },
    {
      uuid: '7b4fc805-9cbb-4f79-a5ff-2856935e8df7',
      side: 'ask',
      ord_type: 'limit',
      price: '536000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T18:00:17+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '804',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1608000',
      trades_count: 4,
      time_in_force: 'fok',
      identifier: 'L1_AAVE_436768a88_a1a0'
    },
    {
      uuid: '79aff800-00fe-46a8-9db1-af6f29ba1a1d',
      side: 'bid',
      ord_type: 'limit',
      price: '537600',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:59:01+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '806.4',
      remaining_fee: '0',
      paid_fee: '806.4',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1612800',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_c38720424_bf8c'
    },
    {
      uuid: 'e0806ec0-1063-4786-a3cd-ec27582b7179',
      side: 'bid',
      ord_type: 'limit',
      price: '537000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:57:57+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '805.5',
      remaining_fee: '0',
      paid_fee: '805.5',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1611000',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'L1_AAVE_da8a23eb5_clear_c72d'
    },
    {
      uuid: '2a0124fe-1498-4e08-868e-3a8ebd52d949',
      side: 'bid',
      ord_type: 'limit',
      price: '537000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:57:52+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '805.5',
      remaining_fee: '0',
      paid_fee: '805.5',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1611000',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'L2_AAVE_96f9e92d6_clear_6568'
    },
    {
      uuid: '8190323b-a5b3-4ab9-8f1d-fd02817baf0c',
      side: 'ask',
      ord_type: 'limit',
      price: '536500',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:57:49+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '804.75',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1609500',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_8c53bfc42_clear_495c'
    },
    {
      uuid: '35678d4b-8066-4b88-a554-ee5c3127b49d',
      side: 'ask',
      ord_type: 'limit',
      price: '536500',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:57:49+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '804.75',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1609500',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_c875e0687_clear_5c53'
    },
    {
      uuid: 'c8e2a5e5-e107-4c46-a125-c8cb129df04a',
      side: 'bid',
      ord_type: 'limit',
      price: '539000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:56:22+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '808.5',
      remaining_fee: '0',
      paid_fee: '808.5',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1617000',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_c875e0687_20e6'
    },
    {
      uuid: 'f70a32a3-2c9c-452f-8d62-5a9dfcf046b7',
      side: 'bid',
      ord_type: 'limit',
      price: '537900',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:56:18+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '806.85',
      remaining_fee: '0',
      paid_fee: '806.85',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1613700',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_243de9586_04ec'
    },
    {
      uuid: 'b6ffdcf2-cf4b-4108-9da5-22fa2e22451d',
      side: 'bid',
      ord_type: 'limit',
      price: '540500',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:56:02+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '810.75',
      remaining_fee: '0.22201665',
      paid_fee: '810.52798335',
      locked: '444.25531665',
      executed_volume: '3',
      executed_funds: '1621055.9667',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_8c53bfc42_ac64'
    },
    {
      uuid: 'def143a4-a169-40c7-ba40-d042916ada6d',
      side: 'ask',
      ord_type: 'limit',
      price: '537000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:55:54+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '805.5',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1611000',
      trades_count: 1,
      identifier: 'S1_AAVE_48f6c04f1_clear_55da'
    },
    {
      uuid: 'c3e4909a-e683-45e8-8796-ac6ae65bf7b8',
      side: 'ask',
      ord_type: 'limit',
      price: '536000',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:46:40+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '804',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1608000',
      trades_count: 1,
      identifier: 'S2_AAVE_cb1a2db21_clear_a960'
    },
    {
      uuid: 'b5614cbf-bc97-46f9-a210-4aac9f344fba',
      side: 'bid',
      ord_type: 'limit',
      price: '534100',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:46:19+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '801.15',
      remaining_fee: '0',
      paid_fee: '801.15',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1602300',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_cb1a2db21_7413'
    },
    {
      uuid: '3574399c-5ea9-4cab-bd46-b07e51471e4d',
      side: 'ask',
      ord_type: 'limit',
      price: '530300',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:38:39+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '795.45',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1590900',
      trades_count: 1,
      identifier: 'S3_AAVE_a86c6f4f5_clear_0888'
    },
    {
      uuid: '8029e16e-5b9e-49d8-8ead-b2da05ce6489',
      side: 'bid',
      ord_type: 'limit',
      price: '528300',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:38:14+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '792.45',
      remaining_fee: '0',
      paid_fee: '792.45',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1584900',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_a86c6f4f5_eab9'
    },
    {
      uuid: 'a118add5-1937-4a9f-863f-68c1ff182dde',
      side: 'ask',
      ord_type: 'limit',
      price: '530200',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:38:10+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '795.3',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1590600',
      trades_count: 1,
      identifier: 'S2_AAVE_e099914b2_clear_0424'
    },
    {
      uuid: 'e2eeb6f7-9499-4494-833c-76c008db157a',
      side: 'ask',
      ord_type: 'limit',
      price: '529400',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:32:43+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '0',
      remaining_fee: '0',
      paid_fee: '794.1',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1588200',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_f0c5c7f33_clear_7e5a'
    },
    {
      uuid: '2eeb9c9c-e332-46d0-8f46-2be91c934a30',
      side: 'bid',
      ord_type: 'limit',
      price: '531300',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:30:45+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '796.95',
      remaining_fee: '0',
      paid_fee: '796.95',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1593900',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S3_AAVE_f0c5c7f33_e5f5'
    },
    {
      uuid: '445a11e0-ea22-4821-adb5-e8ec31b8c37e',
      side: 'bid',
      ord_type: 'limit',
      price: '529600',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:29:32+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '794.4',
      remaining_fee: '0',
      paid_fee: '794.4',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1588800',
      trades_count: 1,
      time_in_force: 'fok',
      identifier: 'S1_AAVE_48f6c04f1_7ab7'
    },
    {
      uuid: 'e463ed5b-56ec-4cc0-b31d-f387727540ca',
      side: 'bid',
      ord_type: 'limit',
      price: '529600',
      state: 'done',
      market: 'KRW-AAVE',
      created_at: '2025-01-22T17:29:32+09:00',
      volume: '3',
      remaining_volume: '0',
      reserved_fee: '794.4',
      remaining_fee: '0',
      paid_fee: '794.4',
      locked: '0',
      executed_volume: '3',
      executed_funds: '1588800',
      trades_count: 2,
      time_in_force: 'fok',
      identifier: 'S2_AAVE_e099914b2_5800'
    }
  ]
const StatusPage = () => {
    const [tableData, setTableData] = useState(null);
    const [assetItems, setAssetItems] = useState(null);
    const [selectedAssetItem, setSelectedAssetItem] = useState(null);
    const { Modal, openModal, closeModal } = useModal();
    const [ratesTableData, setRatesTableData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const ret = await axios.get("https://rest.cefimix.com/redis/all/strategies");
        console.log("Strategies", ret.data);
        const tempTableData = ret.data
        setTableData(tempTableData);
        const tempAssetItems: any[] = _.uniqBy(tempTableData.map((item) => {
            return {
                name: item.targetAsset,
            }
        }), "name");
        tempAssetItems.unshift(null)
        tempAssetItems.unshift({
            name: "All"
        })
        setAssetItems(tempAssetItems);
        setSelectedAssetItem(tempAssetItems[0]);
    }

    const onAssetRateClicked = async () => {
        const ret = await axios.get("https://rest.cefimix.com/quoter/depth");
        const rates = calculateAssetRates(ret.data);
        console.log("Rates", rates);
        setRatesTableData(rates);
        openModal();
    }
    if (_.isNil(tableData)) {
        return <SpinnerMedium />
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Status</PageTitle>
                {/* <SimpleTable data={data}/> */}
                <div className="flex space-x-2 justify-between mb-2">
                    <div>
                        <Select
                            items={assetItems}
                            onChange={setSelectedAssetItem}
                            selectedItem={selectedAssetItem}
                        />
                    </div>
                    <div>
                        <Button
                            onButtonClicked={onAssetRateClicked}
                        >
                            Asset Rate
                        </Button>
                    </div>
                </div>
                <SimpleTable
                    data={tableData.filter((data) => {
                        if (selectedAssetItem.name === "All") {
                            return true;
                        }
                        return data.targetAsset === selectedAssetItem.name;
                    })}
                    columns={columns}
                />
            </div>
            <Modal>
                <div>
                    <PageTitle>Asset Rates</PageTitle>
                    <SimpleTable
                        data={ratesTableData}
                        columns={ratesTableColumns}
                    />
                </div>
            </Modal>
        </Card>
    )
}

export default StatusPage;