import Card from "components/Card";
import SimpleTable from "components/Table/Simple";
import PageTitle from "components/Title/Page";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { columns } from "./config";
import PagingTable from "components/Table/Paging";

const ResultPage = () => {
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        // fetchData();
    }, [])

    const fetchData = async (page: number, limit: number) => {
        const url = "https://rest.cefimix.com/trading/results";
        const params = {
            page,
            limit: limit
        }
        const response = await axios.get(url, { params });
        const {
            total,
            data
        } = response.data;
        return {
            page: page,
            data: data,
            total: total
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Result</PageTitle>
                <PagingTable
                    fetchData={fetchData}
                    columns={columns}
                    windowSize={20}
                />
            </div>
        </Card>
    )
}

export default ResultPage;
