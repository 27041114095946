import React from "react"
import SideNav from "components/SideNav"

const menus = [
    {
        title: "Main",
        subMenus: [
            {
                title: "Home",
                to: "/main/home",
            },
        ],
    },
    {
        title: "Trading",
        subMenus: [
            {
                title: "Realtime",
                to: "/trading/realtime",
            },
            {
                title: "Vision",
                to: "/trading/vision",
            },
            {
                title: "Status",
                to: "/trading/status",
            },
            {
                title: "Result",
                to: "/trading/result",
            },
            {
                title: "Statistics",
                to: "/trading/statistics",
            },
            {
                title: "Value",
                to: "/trading/value",
            },
            {
                title: "Spread",
                to: "/trading/spread",
            },
        ],
    },
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}