import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LwChart from "components/Chart/LwChart";
import { IChartApi, LineStyle, Time } from "lightweight-charts";
import _ from "lodash";
import { basicChartOptions } from "./config";
import moment from "moment";
import CustomDatafeed from "../TvChart/datafeed";
import exchangeConfigs from "apis/exchange/config";
import ExchangeWsClient from "apis/exchange/ws";
import ExchangeApi from "apis/exchange";
import {v4 as uuidv4} from 'uuid';

const EXCHANGE = "Bybit";
const TICKER = "BTC/USDT";
// const EXCHANGE = "Upbit";
// const TICKER = "BTC/KRW";

const FinancialLwChart = ({ exchange = EXCHANGE, ticker = TICKER, onSeriesReady = (seriesRef, uuid) => { } }) => {
    const [chartHandler, setChartHandler] = useState<IChartApi>(null);
    const [exchangeApi] = useState(() => new ExchangeApi(exchange));
    const candleSeriesRef = useRef(null);
    const exchangeConfig = exchangeConfigs[exchange];

    useEffect(() => {
        if (!_.isEmpty(candleSeriesRef.current)) {
            chartHandler.removeSeries(candleSeriesRef.current);
            candleSeriesRef.current = null;
        }
        const tradeWsClient = new ExchangeWsClient(onTradeMsgReceived);
        exchangeApi.getExchangeSymbol(ticker).then((symbol) => {
            tradeWsClient.startWsClient(exchange, symbol, "trade");
        });
        return () => {
            console.log("[VisionPage] useEffect ticker cleanup", ticker);
            tradeWsClient.closeWsClient();
        }
    }, [ticker])

    useEffect(() => {
        console.log("[VisionPage] useEffect chartHandler", chartHandler);
        if (_.isNil(chartHandler)) {
            return;
        }
        let interval = null;
        chartHandler.applyOptions(basicChartOptions)
        const tickerInfo = {
            name: ticker,
            exchange: exchange
        }
        const now = new Date();
        const past = moment(now).subtract(12, 'hour').toDate();
        const nowTsMsec = now.getTime() / 1000;
        const pastTsMsec = past.getTime() / 1000;
        CustomDatafeed.getBars(tickerInfo, "1", { from: pastTsMsec, to: nowTsMsec, countBack: 100 }, onBarHistory, 1000);

        return () => {
            clearInterval(interval);
        }

    }, [chartHandler, ticker])


    const onBarHistory = useCallback((bars, etc) => {
        // console.log("[VisionPage] getBars", bars, etc);
        const candleSeries = chartHandler.addCandlestickSeries({
            // priceLineStyle: LineStyle.Solid,
            // priceLineVisible: false,
        });
        const timeScaledBars = bars.map((bar) => {
            return {
                time: bar.time / 1000,
                open: bar.open,
                high: bar.high,
                low: bar.low,
                close: bar.close
            }
        })
        candleSeries.setData(timeScaledBars);
        candleSeriesRef.current = candleSeries;
        onSeriesReady(candleSeries, uuidv4());
    }, [chartHandler, ticker]);


    const onTradeMsgReceived = useCallback((obj) => {
        if (_.isNil(candleSeriesRef.current)) {
            return;
        }
        // console.log("[VisionPage] onTradeMsgReceived", obj);
        const parsed = exchangeConfig.decodeWsMsg(obj);
        const parsedTradeTick = exchangeConfig.parseTradeTick(parsed);
        // console.log("[VisionPage] parsedTradeTick", parsedTradeTick);
        if (parsedTradeTick.status === "array") {
            handleTradeArr(parsedTradeTick.tradeArr);
            return;
        } else if (parsedTradeTick.status === "diff") {
            handleTrade(parsedTradeTick);
        }
    }, []);

    const handleTradeArr = useCallback((tradeArr) => {
        if (_.isNil(candleSeriesRef.current)) {
            return;
        }
        // console.log("[VisionPage] handleTradeArr", tradeArr);
        // const newTrades = [...bybitTradesRef.current, ...sorted];
        const currentMinutesBinStart = moment().startOf('minute').toDate().getTime();
        const currentMinutesBinEnd = moment().endOf('minute').toDate().getTime();
        const filteredTrades = _.sortBy(tradeArr, 'tradeTime')
            .filter((trade) => trade.tradeTime >= currentMinutesBinStart && trade.tradeTime < currentMinutesBinEnd);
        // bybitTradesRef.current = filteredTrades;
        // console.log("[VisionPage] filteredTrades", filteredTrades);
        if (_.isEmpty(filteredTrades)) {
            return;
        }
        let currentCandle = {
            time: currentMinutesBinStart / 1000 as Time,
            open: filteredTrades[0].tradePrice,
            high: _.maxBy(filteredTrades, 'tradePrice').tradePrice,
            low: _.minBy(filteredTrades, 'tradePrice').tradePrice,
            close: _.last(filteredTrades).tradePrice
        }
        const lastCandle = _.last(candleSeriesRef.current.data()) as any;
        if (lastCandle?.time === currentCandle?.time) {
            currentCandle = {
                ...lastCandle,
                high: Math.max(lastCandle.high, currentCandle.high),
                low: Math.min(lastCandle.low, currentCandle.low),
                close: currentCandle.close
            }
        }
        // console.log("[VisionPage] currentCandle", currentCandle);
        candleSeriesRef.current.update(currentCandle);
    }, []);

    const handleTrade = useCallback((trade) => {
        // console.log("[VisionPage] handleTrade", trade);
        const currentMinutesBinStart = moment().startOf('minute').toDate().getTime();
        const currentMinutesBinEnd = moment().endOf('minute').toDate().getTime();
        if (trade.tradeTime < currentMinutesBinStart || trade.tradeTime >= currentMinutesBinEnd) {
            return;
        }
        const lastCandle = _.last(candleSeriesRef.current.data()) as any;
        let currentCandle = {
            time: currentMinutesBinStart / 1000 as Time,
            open: trade.tradePrice,
            high: trade.tradePrice,
            low: trade.tradePrice,
            close: trade.tradePrice
        }
        if (lastCandle?.time === currentCandle?.time) {
            currentCandle = {
                ...lastCandle,
                high: Math.max(lastCandle.high, currentCandle.high),
                low: Math.min(lastCandle.low, currentCandle.low),
                close: currentCandle.close
            }
        }
        // console.log("[VisionPage] currentCandle", currentCandle);
        candleSeriesRef.current.update(currentCandle);
    }, []);

    return (
        <LwChart onChartReady={setChartHandler} />
    )
}

export default FinancialLwChart;
