import Card from "components/Card";
import Depth from "components/Financial/Depth";
import React, { useCallback, useEffect, useRef, useState } from "react"
import ExchangeWsClient from 'apis/exchange/ws';
import PageTitle from "components/Title/Page";
import ExchangeApi from "apis/exchange";
import exchangeConfigs from "apis/exchange/config";
import _ from "lodash";

const EXCHANGE = "Bybit";
const TICKER = "BTC/USDT";

const FinancialDepth = ({ exchange = EXCHANGE, ticker = TICKER, onDepthChange = (depth) => { } }) => {
    const [exchangeApi] = useState(() => new ExchangeApi(exchange));
    const [depth, setDepth] = useState({ bids: [], asks: [] });
    const wsClientRef = useRef(null);

    useEffect(() => {
        initialize();
        return () => {
            console.log("[FinancialDepth] Cleanup")
            if (_.isNil(wsClientRef.current)) {
                return;
            }
            wsClientRef.current.closeWsClient();
        };
    }, [exchange, ticker]);

    const initialize = async () => {
        setDepth({ bids: [], asks: [] });
        const exchangeSymbol = await exchangeApi.getExchangeSymbol(ticker);
        console.log("[FinancialDepth] Exchange Symbol", exchangeSymbol);
        const wsClient = new ExchangeWsClient(onMsgReceived);
        wsClientRef.current = wsClient;
        wsClient.startWsClient(exchange, exchangeSymbol, 'depth')
        return () => {
            wsClient.closeWsClient()
        }
    }

    const onMsgReceived = useCallback((obj) => {
        const config = exchangeConfigs[exchange];
        const decoded = config.decodeWsMsg(obj);
        setDepth((prevDepth) => {
            const newDepth = config.parseDepthTick(prevDepth, decoded);
            return newDepth;
        })
    }, []);

    useEffect(() => {
        onDepthChange(depth);
    }, [depth])

    return (
        <div>
            <Depth
                askArr={depth.asks}
                bidArr={depth.bids}
                limit={10}
            />
        </div>
    )
}

export default FinancialDepth;
