import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/Card';
import PageTitle from 'components/Title/Page';
import TvChart from 'components/Chart/TvChart';
import { widget as TvWidget, IChartWidgetApi, IChartingLibraryWidget } from 'libs/charting_library';
import CustomDatafeed from './datafeed';
import CustomStreaming from './streaming';
import exchangeConfigs from 'apis/exchange/config'
import ExchangeApi from 'apis/exchange';
import Button from 'components/Form/Button';

const EXCHANGE = "Bybit";
const SYMBOL = "BTCUSDT";
const TICKER = "BTC/USDT";

// const EXCHANGE = "Upbit";
// const TICKER = "BTC/KRW";
// const SYMBOL = "KRW-BTC";

const tvWsClientObj = {};

const onSubscribeBars = async (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    console.log("[Tv Chart] On Subscribe Bars", symbolInfo, subscribeUID)
    const exchangeName = symbolInfo.exchange;
    const ticker = symbolInfo.name;
    const exchangeApi = new ExchangeApi(exchangeName);
    const symbolName = await exchangeApi.getWsSymbol(ticker) ?? await exchangeApi.getExchangeSymbol(ticker)
    const tempWsClient = new CustomStreaming(exchangeName);
    await tempWsClient.startWsClient();
    tvWsClientObj[subscribeUID] = tempWsClient;
    const tradeSubscribeMsg = exchangeApi.config.getTradeSubscribeMsg(symbolName)
    tempWsClient.sendMsg(tradeSubscribeMsg)
    tempWsClient.setTvHandler({ symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback })
}

const onUnSubscribeBars = (subscribeUID) => {
    console.log("[Tv Chart] On UnSubscribe Bars", subscribeUID)
    const tvWsClient = tvWsClientObj[subscribeUID];
    const tvHandler = tvWsClient.getTvHandler();
    const exchangeName = tvHandler.symbolInfo.exchange
    const symbolName = tvHandler.symbolInfo.name;
    const tradeUnSubscribeMsg = exchangeConfigs[exchangeName].getTradeUnsubscribeMsg(symbolName)
    tvWsClient.sendMsg(tradeUnSubscribeMsg)
    tvWsClient.closeWsClient();
    delete tvWsClientObj[subscribeUID]
}

const FinancialTvChart = ({ exchange = EXCHANGE, ticker = TICKER }) => {
    const [tvWidget, setTvWidget] = useState({} as IChartingLibraryWidget);

    const onChartReady = (tvHandler: IChartingLibraryWidget) => {
        console.log("Chart Ready", tvHandler);
        setTvWidget(tvHandler);
    }

    return (
        <Card>
            <div className="p-2 rounded-md flex flex-col">
                <PageTitle>Realtime Chart</PageTitle>
                <Card paddingClassNames='p-0'>
                    <TvChart
                        exchange={exchange}
                        ticker={ticker}
                        dataFeed={{ ...CustomDatafeed, subscribeBars: onSubscribeBars, unsubscribeBars: onUnSubscribeBars }}
                        onChartReady={onChartReady}
                    />
                </Card>
            </div>
        </Card>
    );
}

export default FinancialTvChart;
