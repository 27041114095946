import { IExchangeConfig, IDepth, ILevel } from "apis/exchange/interface";
import _ from "lodash";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1',
    '3': '3',
    '5': '5',
    '10': '10',
    '15': '15',
    '30': '30',
    '60': '60',
    '1D': '24h',
    '1W': '1w',
    '1M': '1m'
}

const UpbitConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/upbit",

    wsEndpoint: "wss://api.upbit.com/websocket/v1",

    defaultTicker: "BTC/KRW",

    spotTickersRestPath: "/v1/market/all",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Tickers] Upbit`, rawSymbolsData)
        if (_.isNil(rawSymbolsData)) {
            throw { msg: "Upbit Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        rawSymbolsData?.forEach((elm) => { // krw market
            const { market: exchangeSymbol } = elm;
            const baseAsset = exchangeSymbol.split('-')[1];
            const quoteAsset = exchangeSymbol.split('-')[0];
            const ticker = `${baseAsset}/${quoteAsset}`;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Upbit`, symbol, resolution)
        return `/v1/candles/minutes/${resolutionMap[resolution]}`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 200,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Upbit`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        const params = {
            market: symbol,
            to: new Date(requestToMsec).toISOString(),
            count: limit,
        }
        return params;
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] Upbit`, rawBarData)
        let bars = [];
        if (_.isNil(rawBarData)) {
            throw { msg: "Upbit Parse Bars Error" }
        }
        const dataArr = rawBarData;
        dataArr.forEach((elm) => {
            bars = [...bars, {
                time: new Date(elm.candle_date_time_kst).getTime(),
                open: Number(elm.opening_price),
                high: Number(elm.high_price),
                low: Number(elm.low_price),
                close: Number(elm.trade_price),
                // volume: Number(elm.v) * Number(elm.o)
                volume: Number(elm.candle_acc_trade_volume)
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        console.log("[Get Depth Subscribe Msg] Upbit", symbol)
        return this.getMultiDepthSubscribeMsg([symbol]);
    },

    getMultiDepthSubscribeMsg: function (symbols: string[]) {
        console.log("[Get Multi Depth Subscribe Msg] Upbit", symbols)
        return [
            {
                "ticket": "test_depth"
            },
            {
                "type": "orderbook",
                "codes": symbols,
            },
        ]
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        console.log("[Get Depth Unsubscribe Msg] Upbit", symbol)
        return this.getMultiDepthUnsubscribeMsg([symbol]);
    },

    getMultiDepthUnsubscribeMsg: function (symbols: string[]) {
        console.log("[Get Multi Depth Unsubscribe Msg] Upbit", symbols)
        return [
            {
                "ticket": "test_depth"
            },
            {
                "type": "orderbook",
                "codes": []
            },
        ]
    },

    parseDepthTick: (prevDepth: IDepth, rawMsgObj: any) => {
        let dataObj = rawMsgObj;
        const asks: ILevel[] = dataObj.orderbook_units.map((unit: any) => [unit.ask_price, unit.ask_size]);
        const bids: ILevel[] = dataObj.orderbook_units.map((unit: any) => [unit.bid_price, unit.bid_size]);
        return { asks, bids };
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Upbit`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("[Get Trade Subscribe Msg] Upbit", symbol)
        return this.getMultiTradeSubscribeMsg([symbol]);
    },

    getMultiTradeSubscribeMsg: function (symbols: string[]) {
        console.log("[Get Multi Trade Subscribe Msg] Upbit", symbols)
        return [
            {
                "ticket": "test_trade"
            },
            {
                "type": "trade",
                "codes": symbols,
            },
        ]
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("[Get Trade Unsubscribe Msg] Upbit", symbol)
        return this.getMultiTradeUnsubscribeMsg([symbol]);
    },

    getMultiTradeUnsubscribeMsg: function (symbols: string[]) {
        console.log("[Get Multi Trade Unsubscribe Msg] Upbit", symbols)
        return [
            {
                "ticket": "test_trade"
            },
            {
                "type": "trade",
                "codes": []
            },
        ]
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Upbit`, rawMsgObj)
        if (rawMsgObj.type !== "trade") {
            console.log("[Parse Trade Tick] Upbit Other Message", rawMsgObj)
            return { status: "other" };
        }
        const tradeTime = Number(rawMsgObj?.trade_timestamp);
        const tradePrice = Number(rawMsgObj?.trade_price);
        const tradeSize = Number(rawMsgObj?.trade_volume);
        return { tradeTime, tradePrice, tradeSize, status: "diff" }
    },

    decodeWsMsg: (rawMsg) => {
        // console.log("[Decode Ws Msg] Upbit", rawMsg)
        var enc = new TextDecoder("utf-8");
        var arr = new Uint8Array(rawMsg.data);
        const decoded = enc.decode(arr);
        const dataObj = JSON.parse(decoded);
        return dataObj;
    },

    parseSymbolFromWsMsg: function (rawMsg: any) {
        return rawMsg.code;
    }
}

export default UpbitConfig;