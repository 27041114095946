import React from "react";
import FinancialTvChart from "containers/Financial/TvChart";
import FinancialDepth from "containers/Financial/Depth";

const EXCHANGE = "Bybit";
const TICKER = "BTC/USDT";

// const EXCHANGE = "Upbit";
// const TICKER = "USDT/KRW";

const RealtimePage = () => {
    return (
        <div>
            <div>
                <FinancialTvChart
                    exchange={EXCHANGE}
                    ticker={TICKER}
                />
            </div>
            <div>
                <FinancialDepth 
                    exchange={EXCHANGE}
                    ticker={TICKER}
                />
            </div>
        </div>
    )
}

export default RealtimePage;