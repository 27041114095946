import React, { useEffect, useState } from "react";
import LwChart from "components/Chart/LwChart";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { IChartApi } from "lightweight-charts";
import axios from "axios";

const COLOR_MAP = {
    "BTC": "yellow",
    "ETH": "purple",
    "XRP": "blue",
    "AAVE": "lime",
    "SOL": "lightblue",
    "USDT": "green"
}

function timeToLocal(originalTime) {
    const d = new Date(originalTime * 1000);
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
}

const SpreadPage = () => {
    const [chartHandler, setChartHandler] = useState<IChartApi>(null);

    useEffect(() => {
        if (!chartHandler) {
            return;
        }
        console.log("Chart Ready", chartHandler);
        // koreanTime
        chartHandler.applyOptions({
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
            },
        })
        fetchData();
    }, [chartHandler])

    const fetchData = async () => {
        const ret = await axios.get("https://rest.cefimix.com/quoter/rates/history");
        const rates = ret.data;
        const assetNames = rates[0].rates.map((item) => {
            return item.asset;
        });
        console.log("Asset Names", assetNames);
        for (const assetName of assetNames) {
            const chartData = rates.map((item) => {
                const value = item.rates.find((rate) => rate.asset === assetName).mid;
                return {
                    time: timeToLocal(new Date(item.createdAt).getTime() / 1000),
                    value: value
                }
            })
            chartData.sort((a, b) => a.time - b.time);
            console.log("Chart Data", chartData);
            const series = chartHandler.addLineSeries({
                color: COLOR_MAP[assetName],
                lineWidth: 2,
            });
            series.setData(chartData);

        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Spread</PageTitle>
                <LwChart onChartReady={setChartHandler} />
            </div>
        </Card>
    )
}

export default SpreadPage;
