import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";

const resolutionMap = {
    '1': '1',
    '3': '3',
    '5': '5',
    '15': '15',
    '30': '30',
    '60': '60',
    '120': '120',
    '240': '240',
    '360': '360',
    '720': '720',
    '1D': '1d',
    '1W': '1w',
    '1M': '1M'
}

const KorbitConfig: IExchangeConfig = {
    //Server
    restProxyBasePath: "/chart/korbit",
    wsEndpoint: "wss://ws.korbit.co.kr/v1/user/push",

    //Symbols
    defaultTicker: "BTC/KRW",
    spotTickersRestPath: "/v1/tickers",
    parseTickers: (rawSymbolsData) => {
        // console.log(`[Parse Symbols] Korbit`, rawSymbolsData)
        if (!rawSymbolsData.success) {
            throw { msg: "Korbit Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        Object.keys(rawSymbolsData?.data).forEach((elm) => {
            const symbolInfo = rawSymbolsData?.data[elm];
            const exchangeSymbol = symbolInfo.symbol;
            const baseAsset = exchangeSymbol.split('_')[0];
            const quoteAsset = exchangeSymbol.split('_')[1];
            const ticker = String(`${baseAsset}/${quoteAsset}`).toUpperCase();
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        return symbolInfoObj;
    },

    //Candles
    getCandleRestPath: (symbol: string, resolution: string) => `/v1/candles/tradingview`,
    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: true,
    hasMonthly: true,
    barLimit: 500,
    getCandleRequestParams: (symbol, requestFromMsec, requestToMsec, limit, resolution) => { // Start -> Start + Limit | End
        return {
            symbol,
            resolution: resolutionMap[resolution],
            count: limit,
            from: requestFromMsec,
            to: requestToMsec,
        }
    },
    parseHistoricalBar: (rawBarData) => {
        console.log(`[Parse Historical Bar] Korbit`, rawBarData)
        let bars = [];
        if (!rawBarData.success) {
            throw { msg: "Korbit Parse Bars Error" }
        }
        rawBarData?.data?.forEach(elm => {
            bars = [...bars, {
                time: Number(elm.t),
                open: Number(elm.o),
                high: Number(elm.l),
                low: Number(elm.h),
                close: Number(elm.c),
                // volume: Number(elm.v) * Number(elm.o)
                volume: Number(elm.v)
            }];
        })
        return bars;
    },

    //Depth
    getSpotDepthRestPath: (symbol) => `/v3/public/quote/depth`,
    getSpotDepthReqParams: (symbol) => ({
        symbol,
        limit: 100
    }),
    parseFullDepth: (rawDepthData) => {
        // console.log(`[Parse Full Depth] ${exchangeName}`, rawDepthData)
        if (rawDepthData.retMsg !== "OK") {
            throw { msg: "Bybit Parse Depth Error" }
        }
        let askArr = rawDepthData?.result?.asks?.map((elm) => [Number(elm[0]), Number(elm[1])])
        let bidArr = rawDepthData?.result?.bids?.map((elm) => [Number(elm[0]), Number(elm[1])])
        let lastUpdateId = rawDepthData.time
        return { bids: bidArr, asks: askArr, lastUpdateId, status: "full" };
    },
    getDepthSubscribeMsg: (symbol) => (
        {
            op: "subscribe",
            args: [`orderbook.40.${symbol}`]
        }
    ),
    getDepthUnsubscribeMsg: (symbol) => (
        {
            op: "unsubscribe",
            args: [`orderbook.40.${symbol}`]
        }
    ),
    parseDepthTick: (prevDepth, rawMsgObj) => {
        // console.log(`[Parse Depth Tick] ${exchangeName}`, rawMsgObj)
        if (rawMsgObj.type === "delta") {
            const askArr = rawMsgObj?.data?.a?.map((elm) => [Number(elm[0]), Number(elm[1])]);
            const bidArr = rawMsgObj?.data?.b?.map((elm) => [Number(elm[0]), Number(elm[1])]);
            return { bidArr, askArr, status: "full" };
        }
        return { status: "otherMsg" };
    },

    //Trades
    getSpotTradeRestPath: (symbol) => `/v3/public/quote/trades`,
    getSpotTradeReqParams: (symbol) => ({
        symbol,
        limit: 60 // Default 60, Max 60
    }),
    parseFullTrade: (rawTradeData) => {
        // console.log(`[Parse Full Trade] ${exchangeName}`, rawTradeData)
        if (rawTradeData.retMsg !== "OK") {
            throw { msg: "Bybit Parse Depth Error" }
        }
        let tradeArr = [];
        rawTradeData?.result?.list?.forEach((elm) => {
            const tradeTime = Number(elm.time);
            const tradePrice = Number(elm.price);
            const tradeSize = Number(elm.qty);
            const newTrade = {
                time: tradeTime,
                price: tradePrice,
                size: tradeSize,
                amount: tradePrice * tradeSize
            }
            tradeArr.push(newTrade)
        })
        return { tradeArr, status: "full" };
    },
    getTradeSubscribeMsg: (symbol) => (
        {
            "accessToken": null,
            "timestamp": Date.now(),
            "event": "korbit:subscribe",
            "data": {
                "channels": [`transaction:${symbol}`]
            }
        }
    ),
    getTradeUnsubscribeMsg: (symbol) => (
        {
            "accessToken": null,
            "timestamp": Date.now(),
            "event": "korbit:unsubscribe",
            "data": {
                "channels": [`transaction:${symbol}`]
            }
        }
    ),
    parseTradeTick: (rawMsgObj) => {
        console.log(`[Parse Trade Tick] Korbit`, rawMsgObj)
        if (rawMsgObj?.event === 'korbit:push-transaction') {
            const tradeTime = Number(rawMsgObj?.timestamp);
            const tradePrice = Number(rawMsgObj?.data?.price);
            const tradeSize = Number(rawMsgObj?.data?.amount);
            return { tradeTime, tradePrice, tradeSize, status: "diff" }
        } else {
            return { status: "otherMsg" }
        }
    }
}

export default KorbitConfig;