import Card from 'components/Card';
import PageTitle from 'components/Title/Page';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LwChart, { EXAMPLE_LW_CHART_DATA } from 'components/Chart/LwChart';
import { IChartApi, ISeriesApi, Time, LineStyle, IPriceLine } from 'lightweight-charts';
import _ from 'lodash';
import Button from 'components/Form/Button';
import moment from 'moment-timezone';
import { askLineOptions, bidLineOptions, mirrorAskLineOptions, mirrorBidLineOptions, bybitOrdersTableColumn, upbitOrdersTableColumn } from './config';
import ExchangeWsClient from 'apis/exchange/ws';
import ExchangeApi from 'apis/exchange';
import exchangeConfigs from 'apis/exchange/config';
import CustomDatafeed from 'containers/Financial/TvChart/datafeed';
import Input from 'components/Form/Input';
import Depth from 'components/Financial/Depth';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import EmptyForm from 'components/Form/Empty';
import axios from 'axios';
import SimpleTable, { Column } from 'components/Table/Simple';
import { XMarkIcon } from '@heroicons/react/24/outline';
import FinancialLwChart from 'containers/Financial/LwChart';
import Select from 'components/Form/Select';
import SpinnerMedium from 'components/Spinner/Medium';
import FinancialDepth from 'containers/Financial/Depth';
import { columns as statusColumns } from '../Status/config';

const EXCHANGE = "Bybit";
const TICKER = "BTC/USDT";

const bybitConf = exchangeConfigs[EXCHANGE];
const upbitConf = exchangeConfigs["Upbit"];

const CLOSING_SL_RATE = 0.05;
const CLEARING_SL_RATE = 0.05;

const VisionPage = () => {
    const [chartHandler, setChartHandler] = useState<IChartApi>(null);
    const linesRef = useRef<{ [uuid: string]: { [orderId: string]: IPriceLine } }>({});
    const bybitSeriesRef = useRef<{ [uuid: string]: ISeriesApi<"Candlestick"> }>({});
    const [currensBybitSeriesUuid, setCurrentBybitSeriesUuid] = useState(null);
    const upbitSeriesRef = useRef<{ [uuid: string]: ISeriesApi<"Candlestick"> }>({});
    const [currensUpbitSeriesUuid, setCurrentUpbitSeriesUuid] = useState(null);
    // const [bybitSeriesHandler, setBybitSeriesHandler] = useState<ISeriesApi<"Candlestick">>(null);
    // const [upbitSeriesHandler, setUpbitSeriesHandler] = useState<ISeriesApi<"Candlestick">>(null);
    const [isReady, setIsReady] = useState(false);

    const redSeriesRef = useRef<ISeriesApi<"Line">>(null);
    const greenSeriesRef = useRef<ISeriesApi<"Line">>(null);
    const pinkSereisRef = useRef<ISeriesApi<"Line">>(null);
    const mintSeriesRef = useRef<ISeriesApi<"Line">>(null);

    const candleSeriesRef = useRef<ISeriesApi<"Candlestick">>(null);

    const [bybitDepth, setBybitDepth] = useState(null);
    const [upbitDepth, setUpbitDepth] = useState(null);

    const bybitDepthRef = useRef(null);
    const upbitDepthRef = useRef(null);
    const exRateDepthRef = useRef(null);

    const bybitTradesRef = useRef([]);

    const priceInputRef = useRef(null);
    const sizeInputRef = useRef(null);

    const [ordersTableData, setOrdersTableData] = useState([]);

    const [assetItems, setAssetItems] = useState(null);
    const [selectedAssetItem, setSelectedAssetItem] = useState(null);

    const [strategies, setStrategies] = useState(null);
    const [allBybitOrders, setAllBybitOrders] = useState([]);
    const [allUpbitOrders, setAllUpbitOrders] = useState([]);

    const priceLines = useRef({});
    const guideLines = useRef({
        l1: null,
        l2: null,
        l3: null,
        l4: null,
        l5: null,
        s1: null,
        s2: null,
        s3: null,
        s4: null,
        s5: null,
    });

    const usdtTicker = `${selectedAssetItem?.name}/USDT`;
    const krwTicker = `${selectedAssetItem?.name}/KRW`;

    useEffect(() => {
        initExchangeApis();
        fetchAssetData();
        const interval = setInterval(() => {
            fetchStrategyData();
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {

    }, []);

    useEffect(() => {
        console.log("[VisionPage] useEffect chartHandler", chartHandler);
        if (_.isNil(chartHandler)) {
            return;
        }

        const redSeries = chartHandler.addLineSeries();
        redSeries.applyOptions(askLineOptions)
        redSeriesRef.current = redSeries;

        const greenSeries = chartHandler.addLineSeries();
        greenSeries.applyOptions(bidLineOptions)
        greenSeriesRef.current = greenSeries;

        const pinkSeries = chartHandler.addLineSeries();
        pinkSeries.applyOptions(mirrorAskLineOptions)
        pinkSereisRef.current = pinkSeries;

        const mintSeries = chartHandler.addLineSeries();
        mintSeries.applyOptions(mirrorBidLineOptions)
        mintSeriesRef.current = mintSeries;

        const interval = setInterval(async () => {
            // addData();
            const ret = await axios.get("http://localhost:5101/trading/bybit/orders");
            const data = ret?.data?.result?.list;
            // console.log("[VisionPage] orders", data);
            if (_.isNil(data)) {
                return;
            }
            setOrdersTableData(data);
            for await (const order of data) {
                // if not exist in priceLines, add
                if (_.isNil(priceLines.current[order.orderId])) {
                    addLine(Number(order.price), order.side, order.orderId);
                }
            }
            const orderIds = data.map((order) => order.orderId);
            // remove priceLines not in orderIds
            const priceLineKeys = Object.keys(priceLines.current);
            for await (const key of priceLineKeys) {
                if (!orderIds.includes(key)) {
                    removeLine(key);
                }
            }
            // console.log("[VisionPage] orders", data);
            updateGuidelines();
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [chartHandler])

    useEffect(() => {
        // console.log("[VisionPage] useEffect allBybitOrders", currensBybitSeriesUuid);
        // if (_.isNil(bybitSeriesHandler)) {
        //     return;
        // }
        if (_.isEmpty(allBybitOrders)) {
            return;
        }
        const series = bybitSeriesRef.current[currensBybitSeriesUuid];
        if (_.isNil(series)) {
            console.log("[VisionPage] series is nil", usdtTicker);
            return;
        }
        // console.log("[VisionPage] allBybitOrders", series);

        allBybitOrders.filter((order) => order.symbol.includes(selectedAssetItem.name)).filter((order) => order.orderStatus === "New").forEach((order) => {
            // console.log("[VisionPage] order", order);
            const {orderLinkId} = order;
            const tier = orderLinkId.split("_")[0];
            if (_.isNil(linesRef?.current?.[currensBybitSeriesUuid]?.[order.orderId])) {
                if (_.isNil(linesRef.current[currensBybitSeriesUuid])) {
                    linesRef.current[currensBybitSeriesUuid] = {};
                }
                const line = series.createPriceLine({
                    price: Number(order.price),
                    color: order.side === "Buy" ? 'green' : 'red',
                    lineWidth: 1,
                    axisLabelVisible: true,
                    lineStyle: LineStyle.Solid,
                    title: tier,
                })
                linesRef.current[currensBybitSeriesUuid][order.orderId] = line;
                return;
            }
            const line = linesRef.current?.[currensBybitSeriesUuid][order.orderId];
            line.applyOptions({
                price: Number(order.price),
            })
        })

        if (_.isNil(linesRef.current?.[currensBybitSeriesUuid])) {
            return;
        }

        Object.keys(linesRef.current?.[currensBybitSeriesUuid]).filter((key) => !key.endsWith("SL")).forEach((key) => {
            const order = allBybitOrders.filter((order) => order.orderStatus === "New").find((order) => order.orderId === key);
            if (_.isNil(order)) {
                const line = linesRef.current?.[currensBybitSeriesUuid][key];
                try {
                    series.removePriceLine(line);
                    delete linesRef.current?.[currensBybitSeriesUuid][key];
                } catch (e) {
                    console.log("[VisionPage] removePriceLine error", e);
                }
            }
        });
    }, [allBybitOrders, selectedAssetItem, currensBybitSeriesUuid])

    useEffect(() => {
        // console.log("[VisionPage] useEffect bybitDepth", currensBybitSeriesUuid);
        if (_.isNil(bybitDepth)) {
            console.log("[VisionPage] bybitDepth is nil", currensBybitSeriesUuid);
            return;
        }
        const series = bybitSeriesRef.current[currensBybitSeriesUuid];
        if (_.isNil(series)) {
            console.log("[VisionPage] series is nil", usdtTicker);
            return;
        }
        const longSlTitle = `${usdtTicker}_Long_SL`;
        const shortSlTitle = `${usdtTicker}_Short_SL`;

        const bestBidPrice = bybitDepth?.bids[0]?.[0];
        const bestAskPrice = bybitDepth?.asks[0]?.[0];
        const longSlPrice = bestBidPrice * (1 - CLOSING_SL_RATE);
        const shortSlPrice = bestAskPrice * (1 + CLOSING_SL_RATE);
        // console.log(usdtTicker, currensBybitSeriesUuid, longSlTitle, linesRef.current, _.isNil(linesRef.current?.[currensBybitSeriesUuid]?.[longSlTitle]))
        if (_.isNil(linesRef.current?.[currensBybitSeriesUuid]?.[longSlTitle])) {
            if (_.isNil(linesRef.current[currensBybitSeriesUuid])) {
                linesRef.current[currensBybitSeriesUuid] = {};
            }
            const bidLine = series.createPriceLine({
                price: longSlPrice,
                color: 'orange',
                lineWidth: 1,
                axisLabelVisible: true,
                title: 'SHORT_SL',
                lineStyle: LineStyle.Solid,
            })
            const askLine = series.createPriceLine({
                price: shortSlPrice,
                color: 'purple',
                lineWidth: 1,
                axisLabelVisible: true,
                title: 'LONG_SL',
                lineStyle: LineStyle.Solid,
            })
            linesRef.current[currensBybitSeriesUuid][longSlTitle] = bidLine;
            linesRef.current[currensBybitSeriesUuid][shortSlTitle] = askLine;
            return;
        }
        linesRef.current[currensBybitSeriesUuid][longSlTitle].applyOptions({
            price: longSlPrice,
        })
        linesRef.current[currensBybitSeriesUuid][shortSlTitle].applyOptions({
            price: shortSlPrice,
        })
    }, [bybitDepth, currensBybitSeriesUuid])

    useEffect(() => {
        // console.log("[VisionPage] useEffect upbitSeriesHandler", allUpbitOrders);
        // if (_.isNil(upbitSeriesHandler)) {
        //     return;
        // }
        if (_.isEmpty(allUpbitOrders)) {
            return;
        }
        const series = upbitSeriesRef.current[currensUpbitSeriesUuid];
        if (_.isNil(series)) {
            console.log("[VisionPage] series is nil", krwTicker);
            return;
        }
        allUpbitOrders.filter((order) => order.market.includes(selectedAssetItem.name)).filter((order) => order.state === "wait").forEach((order) => {
            // console.log("[VisionPage] order", order);
            if (_.isNil(linesRef?.current?.[currensUpbitSeriesUuid]?.[order.uuid])) {
                if (_.isNil(linesRef.current[currensUpbitSeriesUuid])) {
                    linesRef.current[currensUpbitSeriesUuid] = {};
                }
                const line = series.createPriceLine({
                    price: Number(order.price),
                    color: order.side === "bid" ? 'green' : 'red',
                    lineWidth: 1,
                    axisLabelVisible: true,
                    lineStyle: LineStyle.Solid,
                    // title: 'Price Line',
                })
                linesRef.current[currensUpbitSeriesUuid][order.uuid] = line;
                return;
            }
            const line = linesRef.current?.[currensUpbitSeriesUuid][order.uuid];
            line.applyOptions({
                price: Number(order.price),
            })
        })

        if (_.isNil(linesRef.current?.[currensUpbitSeriesUuid])) {
            return;
        }

        Object.keys(linesRef.current?.[currensUpbitSeriesUuid]).filter((key) => !key.endsWith("SL")).forEach((key) => {
            const order = allUpbitOrders.filter((order) => order.state === "wait").find((order) => order.uuid === key);
            if (_.isNil(order)) {
                try {
                    const line = linesRef.current?.[currensUpbitSeriesUuid][key];
                    series.removePriceLine(line);
                    delete linesRef.current?.[currensUpbitSeriesUuid][key];
                } catch (e) {
                    console.log("[VisionPage] removePriceLine error", e);
                }
            }
        });
    }, [allUpbitOrders, selectedAssetItem, currensUpbitSeriesUuid])

    useEffect(() => {
        if (_.isNil(upbitDepth)) {
            return;
        }
        const series = upbitSeriesRef.current[currensUpbitSeriesUuid];
        if (_.isNil(series)) {
            return;
        }
        const longSlTitle = `${krwTicker}_BUY_SL`;
        const shortSlTitle = `${krwTicker}_SELL_SL`;

        const bestAskPrice = upbitDepth?.asks[0]?.[0];
        const bestBidPrice = upbitDepth?.bids[0]?.[0];
        const buySlPrice = bestAskPrice * (1 - CLEARING_SL_RATE); // different side with bybit
        const sellSlPrice = bestBidPrice * (1 + CLEARING_SL_RATE);
        if (_.isNil(linesRef.current?.[currensUpbitSeriesUuid]?.[longSlTitle])) {
            if (_.isNil(linesRef.current[currensUpbitSeriesUuid])) {
                linesRef.current[currensUpbitSeriesUuid] = {};
            }
            const bidLine = series.createPriceLine({
                price: buySlPrice,
                color: 'orange',
                lineWidth: 1,
                axisLabelVisible: true,
                title: 'SELL_SL',
                lineStyle: LineStyle.Solid,
            })
            const askLine = series.createPriceLine({
                price: sellSlPrice,
                color: 'blue',
                lineWidth: 1,
                axisLabelVisible: true,
                title: 'BUY_SL',
                lineStyle: LineStyle.Solid,
            })
            linesRef.current[currensUpbitSeriesUuid][longSlTitle] = bidLine;
            linesRef.current[currensUpbitSeriesUuid][shortSlTitle] = askLine;
            return;
        }
        linesRef.current[currensUpbitSeriesUuid][longSlTitle].applyOptions({
            price: buySlPrice,
        })
        linesRef.current[currensUpbitSeriesUuid][shortSlTitle].applyOptions({
            price: sellSlPrice,
        })
    }, [upbitDepth, currensUpbitSeriesUuid])


    const initExchangeApis = async () => {
        const upbitApis = new ExchangeApi("Upbit");
        const bybitApis = new ExchangeApi("Bybit");
        await upbitApis.init();
        await bybitApis.init();
        setIsReady(true);
    }

    const fetchAssetData = async () => {
        const ret = await axios.get("https://rest.cefimix.com/redis/all/strategies");
        const strategiesData = ret.data;
        const tempTableData = strategiesData
        // setTableData(tempTableData);
        const tempAssetItems: any[] = _.uniqBy(tempTableData.map((item) => {
            return {
                name: item.targetAsset,
            }
        }), "name");
        setAssetItems(tempAssetItems);
        setSelectedAssetItem(tempAssetItems[0]);
    }

    const fetchStrategyData = async () => {
        const ret = await axios.get("https://rest.cefimix.com/redis/all/strategies");
        const strategiesData = ret.data;
        setStrategies(strategiesData);
        const allBybitOrdersTemp: any[] = [];
        const allUpbitOrdersTemp: any[] = [];
        for (const strategy of strategiesData) {
            if (!_.isNil(strategy.makingOrder)) {
                allBybitOrdersTemp.push(strategy.makingOrder);
            }
            if (!_.isNil(strategy.closingOrder)) {
                allBybitOrdersTemp.push(strategy.closingOrder);
            }
            if (!_.isNil(strategy.hedgingOrder)) {
                allUpbitOrdersTemp.push(strategy.hedgingOrder);
            }
            if (!_.isNil(strategy.clearingOrder)) {
                allUpbitOrdersTemp.push(strategy.clearingOrder);
            }
        }
        // console.log("[VisionPage] allBybitOrders", allBybitOrdersTemp);
        // console.log("[VisionPage] allUpbitOrders", allUpbitOrdersTemp);
        setAllBybitOrders(allBybitOrdersTemp);
        setAllUpbitOrders(allUpbitOrdersTemp);
        const openBybitOrders = allBybitOrdersTemp.filter((order) => order.orderStatus === "New");
        const openUpbitOrders = allUpbitOrdersTemp.filter((order) => order.state === "wait");
        // console.log("[VisionPage] openBybitOrders", openBybitOrders);
        // console.log("[VisionPage] openUpbitOrders", openUpbitOrders);
    }

    const addData = async () => {
        console.log("[VisionPage] addData");
        if (_.isNil(redSeriesRef.current)) {
            return;
        }
        const askData: readonly any[] = redSeriesRef.current.data();
        const bidData: readonly any[] = greenSeriesRef.current.data();
        const mirrorAskData: readonly any[] = pinkSereisRef.current.data();
        const mirrorBidData: readonly any[] = mintSeriesRef.current.data();
        // console.log("[VisionPage] addData", askData);
        // const lastDate = _.last(data)?.time;
        // remove first data if more than 10
        // if (askData.length >= 200) {
        //     askSeriesRef.current.setData(askData.slice(1));
        //     bidSeriesRef.current.setData(bidData.slice(1));
        //     mirrorAskSeriesRef.current.setData(mirrorAskData.slice(1));
        //     mirrorBidSeriesRef.current.setData(mirrorBidData.slice(1));
        // }
        const now = new Date().getTime() / 1000 as Time;

        const feeRate = 0.0005; // BTC
        const feeRate2 = 0.0002; // USDT

        const bestAskExRate = exRateDepthRef.current?.asks[0]?.[0] || 0;
        const bestBidExRate = exRateDepthRef.current?.bids[0]?.[0] || 0;
        const upbitBestAsk = upbitDepthRef.current?.asks[0]?.[0] || 0;
        const upbitBestBid = upbitDepthRef.current?.bids[0]?.[0] || 0;
        const bybitBestAsk = bybitDepthRef.current?.asks[0]?.[0] || 0;
        const bybitBestBid = bybitDepthRef.current?.bids[0]?.[0] || 0;

        redSeriesRef.current.update({
            time: now,
            value: bestAskExRate || 0
        })
        greenSeriesRef.current.update({
            time: now,
            value: bestBidExRate || 0
        })

        pinkSereisRef.current.update({
            time: now,
            value: upbitBestAsk / bybitBestBid / (1 - feeRate) / (1 - feeRate2) || 0
        })

        mintSeriesRef.current.update({
            time: now,
            value: upbitBestBid / bybitBestAsk * (1 - feeRate) * (1 - feeRate2) || 0
        })
    }

    const addLine = async (price: number, side: "Buy" | "Sell", orderId) => {
        console.log("[VisionPage] addLine", price);
        const line = candleSeriesRef.current.createPriceLine({
            price: price,
            color: side === "Buy" ? 'green' : 'red',
            lineWidth: 1,
            axisLabelVisible: true,
            title: 'Price Line',
        });
        priceLines.current[orderId] = line;
        // candleSeriesRef.current.removePriceLine(line);
    }

    const removeLine = async (orderId) => {
        console.log("[VisionPage] removeLine", orderId);
        const line = priceLines.current[orderId];
        if (_.isNil(line)) {
            return;
        }
        candleSeriesRef.current.removePriceLine(line);
        delete priceLines.current[orderId];
    }

    const updateDepthLine = () => {
        if (_.isNil(pinkSereisRef.current) || _.isNil(mintSeriesRef.current)) {
            return;
        }
        if (_.isNil(candleSeriesRef.current)) {
            return;
        }
        const lastBar = _.last(candleSeriesRef.current.data()) as any;
        const lastBarTime = lastBar.time;
        const bestAskExRate = exRateDepthRef.current?.asks[0]?.[0] || 0;
        const bestBidExRate = exRateDepthRef.current?.bids[0]?.[0] || 0;
        const upbitBestAsk = upbitDepthRef.current?.asks[0]?.[0] || 0;
        const upbitBestBid = upbitDepthRef.current?.bids[0]?.[0] || 0;
        redSeriesRef.current.update({
            time: lastBarTime as Time,
            value: upbitBestAsk / (bestBidExRate - 1) || 0
        });
        pinkSereisRef.current.update({
            time: lastBarTime as Time,
            value: upbitBestAsk / bestBidExRate || 0
        })

        mintSeriesRef.current.update({
            time: lastBarTime as Time,
            value: upbitBestBid / bestAskExRate || 0
        })

        greenSeriesRef.current.update({
            time: lastBarTime as Time,
            value: upbitBestBid / (bestAskExRate + 1) || 0
        })
    }

    const updateGuidelines = async () => {
        const { data: longTier } = await axios.get("http://localhost:5101/redis/object/longTier");
        const { data: shortTier } = await axios.get("http://localhost:5101/redis/object/shortTier");
        console.log("[VisionPage] updateGuidelines", longTier, shortTier);
        for (let i = 0; i < longTier.length; i++) {
            const key = `l${i + 1}`;
            const price = longTier[i].price;
            if (_.isNil(guideLines.current[key])) {
                guideLines.current[key] = candleSeriesRef.current.createPriceLine({
                    price: price,
                    color: 'purple',
                    lineWidth: 1,
                    lineStyle: LineStyle.Solid,
                    axisLabelVisible: true,
                    title: key,
                });
                return;
            }
            guideLines.current[key].applyOptions({
                price: price,
            })
        }
        for (let i = 0; i < shortTier.length; i++) {
            const key = `s${i + 1}`;
            const price = shortTier[i].price;
            if (_.isNil(guideLines.current[key])) {
                guideLines.current[key] = candleSeriesRef.current.createPriceLine({
                    price: price,
                    color: 'orange',
                    lineWidth: 1,
                    lineStyle: LineStyle.Solid,
                    axisLabelVisible: true,
                    title: key,
                });
                return;
            }
            guideLines.current[key].applyOptions({
                price: price,
            })
        }
    }

    const onOrderRequested = async (side: "Buy" | "Sell") => {
        const body = {
            side,
            symbol: "BTCUSDT",
            price: priceInputRef.current,
            size: sizeInputRef.current
        }
        console.log("[VisionPage] onOrderRequested", body);
        try {
            const ret = await axios.post("http://localhost:5101/trading/bybit/order", body);
            console.log("[VisionPage] onOrderRequested ret", ret);
        } catch (e) {
            console.log("[VisionPage] onOrderRequested error", e);
        }
    }

    const columnsWithActions: Column[] = [
        {
            field: "cancelButton",
            label: "Cancel",
            render: (value, field, row) => {
                const orderId = row.orderId;
                return (
                    <div className="flex justify-center items-center">
                        <XMarkIcon
                            className="size-4 cursor-pointer"
                            onClick={async () => {
                                // removeLine(orderId);
                                const body = {
                                    orderId,
                                    symbol: "BTCUSDT",

                                }
                                console.log("[VisionPage] onOrderRequested", body);
                                try {
                                    const ret = await axios.delete("http://localhost:5101/trading/bybit/order", { data: body });
                                    console.log("[VisionPage] onOrderRequested ret", ret);
                                } catch (e) {
                                    console.log("[VisionPage] onOrderRequested error", e);
                                }
                            }}
                        />
                    </div>
                )
            }
        },
        ...bybitOrdersTableColumn,
    ]

    if (_.isNil(selectedAssetItem) || !isReady) {
        return <SpinnerMedium />
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Vision</PageTitle>
                {/* <div className="flex space-x-1">
                    <div>
                        <Input
                            label='Price'
                            value={priceInputRef.current}
                            onChange={(value) => priceInputRef.current = value}
                        />
                    </div>
                    <div>
                        <Button onButtonClicked={addLine}>
                            Add Line
                        </Button>
                    </div>
                </div> */}
                <div className="flex ps-2">
                    <div>
                        <Select
                            items={assetItems}
                            onChange={setSelectedAssetItem}
                            selectedItem={selectedAssetItem}
                        />
                    </div>
                </div>
                <div>
                    <Card>
                        <div className="p-2">
                            <PageTitle>Strategies</PageTitle>
                            <SimpleTable
                                data={strategies?.filter((strategy) => strategy?.targetAsset === selectedAssetItem?.name)}
                                columns={statusColumns}
                            />
                        </div>
                    </Card>
                </div>
                <div className="flex flex-col space-y-1">
                    <div className="flex flex-col md:flex-row">
                        <div className="md:w-[50%]">
                            <Card>
                                <div className="ps-2 pt-2">
                                    <PageTitle>Bybit</PageTitle>
                                </div>
                                <div className="overflow-scroll">
                                    <div className="">
                                        <Card>
                                            <FinancialLwChart exchange='Bybit' ticker={usdtTicker} onSeriesReady={(seriesHandler, uuid) => {
                                                console.log("[VisionPage] Bybit Series Ready", usdtTicker, seriesHandler, uuid);
                                                setCurrentBybitSeriesUuid(uuid);
                                                bybitSeriesRef.current[uuid] = seriesHandler;
                                            }} />
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <div>
                                            <SimpleTable
                                                data={allBybitOrders.filter((order) => order.symbol.includes(selectedAssetItem.name)).filter((order) => order.orderStatus === "New")}
                                                columns={bybitOrdersTableColumn}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <FinancialDepth exchange='Bybit' ticker={usdtTicker} onDepthChange={setBybitDepth} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="md:w-[50%]">
                            <Card>
                                <div className="ps-2 pt-2">
                                    <PageTitle>Upbit</PageTitle>
                                </div>
                                <div className="overflow-scroll">
                                    <div className="">
                                        <Card>
                                            <FinancialLwChart exchange='Upbit' ticker={krwTicker} onSeriesReady={(seriesHandler, uuid) => {
                                                console.log("[VisionPage] Upbit Series Ready", krwTicker);
                                                setCurrentUpbitSeriesUuid(uuid);
                                                upbitSeriesRef.current[uuid] = seriesHandler;
                                            }} />
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <div>
                                            <SimpleTable
                                                data={allUpbitOrders.filter((order) => order.market.includes(selectedAssetItem.name)).filter((order) => order.state === "wait")}
                                                columns={upbitOrdersTableColumn}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <FinancialDepth exchange='Upbit' ticker={krwTicker} onDepthChange={setUpbitDepth} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <div className="w-full">
                            <Card>
                                <div className="ps-2 pt-2">
                                    <PageTitle>Exchange Rate</PageTitle>
                                </div>
                                <div className="">
                                    <Card>
                                        <FinancialLwChart exchange='Upbit' ticker={"USDT/KRW"} />
                                    </Card>
                                </div>
                                <Tabs
                                    linkSearchParam={false}
                                    isEvenFullWidth={true}
                                >
                                    <Tab
                                        title="Depth"
                                        path='depth'
                                    >
                                        <div className="p-3 flex flex-col space-y-2 overflow-scroll">
                                            <div>
                                                <FinancialDepth exchange='Upbit' ticker={"USDT/KRW"} />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab
                                        title="Trades"
                                        path='trades'
                                    >
                                        <EmptyForm>
                                            Trades
                                        </EmptyForm>
                                    </Tab>
                                </Tabs>
                            </Card>
                        </div>
                        <div className="w-full">
                            <Card>
                                <Tabs
                                    linkSearchParam={false}
                                    isEvenFullWidth={true}
                                >
                                    <Tab
                                        title='Open'
                                        path="open"
                                    >
                                        <div className="flex flex-col space-y-3 p-3">
                                            <Input
                                                label='Price'
                                                value={priceInputRef.current}
                                                onChange={(value) => priceInputRef.current = value}
                                            />
                                            <Input
                                                label='Size'
                                                value={sizeInputRef.current}
                                                onChange={(value) => sizeInputRef.current = value}
                                            />
                                            <div className="flex space-x-2">
                                                <div className="w-full">
                                                    <Button onButtonClicked={() => { onOrderRequested("Buy") }}>
                                                        Long
                                                    </Button>
                                                </div>
                                                <div className="w-full">
                                                    <Button onButtonClicked={() => { onOrderRequested("Sell") }}>
                                                        Short
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab
                                        title="Close"
                                        path="close"
                                    >
                                        Close
                                    </Tab>
                                </Tabs>
                            </Card>
                            <Card>
                                <div className="p-3">
                                    <SimpleTable
                                        data={ordersTableData}
                                        columns={columnsWithActions}
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
        </Card>
    )
}

export default VisionPage;
