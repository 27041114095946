import { formatNumber } from "components/Financial/Depth";
import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";
import moment from "moment-timezone";

export const columns: Column[] = [
    {
        field: "strategyId",
        label: "Strategy ID",
    },
    {
        field: "targetAsset",
        label: "Target Asset",
    },
    {
        field: "targetMakingTicker",
        visible: false,
    },
    {
        field: "targetMakingSymbol",
        visible: false,
    },
    {
        field: "targetHedgingTicker",
        visible: false,
    },
    {
        field: "targetHedgingSymbol",
        visible: false,
    },
    {
        field: "tierId",
        label: "Tier ID",
    },
    {
        field: "state",
        label: "State",
    },
    // {
    //     field: "estimatedProfit",
    //     label: "Estimated Profit",
    //     render: (value, field, row) => {
    //         if ((!row.hedgingOrder) || (!row.makingOrder)) {
    //             return "";
    //         }
    //         const { price: makingPrice } = row?.makingOrder;
    //         const { price: hedgingPrice } = row?.hedgingOrder;
    //         const hedgingRate = hedgingPrice / makingPrice;
    //         const makingValue = row.makingOrder?.cumExecValue;
    //         const tierId = row.tierId;
    //         const midRate = row.midRate;
    //         const fee = makingValue * (0.0005 + 0.0002) * midRate;
    //         if (tierId.startsWith("S")) {
    //             return formatNumber((midRate - hedgingRate) * makingValue - 2 * fee, 0);
    //         } else if (tierId.startsWith("L")) {
    //             return formatNumber((hedgingRate - midRate) * makingValue - 2 * fee, 0);
    //         }
    //     }
    // },
    {
        field: "Making Profit",
        label: "Making Profit",
        render: (value, field, row) => {
            if (!row.makingOrder || !row.hedgingOrder || !row.hedgingExOrder) {
                return "";
            }
            const { executed_funds: hedgingValue, paid_fee: hedgingFee } = row.hedgingOrder;
            const { executed_funds: hedgingExValue, paid_fee: hedgingExFee, price: hedgingExPrice } = row.hedgingExOrder;
            const { cumExecFee: makingFee } = row.makingOrder;
            let makingProfit = 0;
            if (row.tierId.startsWith("L")) {
                makingProfit = hedgingValue - hedgingExValue - hedgingFee - hedgingExFee - makingFee * hedgingExPrice;
            } else if (row.tierId.startsWith("S")) {
                makingProfit = hedgingExValue - hedgingValue - hedgingFee - hedgingExFee - makingFee * hedgingExPrice;
            }
            return formatNumber(makingProfit, 0);
        }
    },
    {
        field: "hedgeRate",
        label: "Hedge Rate",
        render: (value, field, row) => {
            if ((!row.hedgingOrder) || (!row.makingOrder)) {
                return "";
            }
            const { price: makingPrice } = row?.makingOrder;
            const { price: hedgingPrice } = row?.hedgingOrder;
            return formatNumber(hedgingPrice / makingPrice, 2);
        }
    },
    {
        field: "clearingRate",
        label: "Clearing Rate",
        render: (value, field, row) => {
            if (!row.clearingOrder) {
                return "";
            }
            const { price: closingPrice } = row?.closingOrder;
            const { price: clearingPrice } = row?.clearingOrder;
            return formatNumber(clearingPrice / closingPrice, 2);
        }
    },
    {
        field: "midRate",
        label: "Mid Rate",
    },
    {
        field: "makingOrderId",
        label: "Making Order ID",
        visible: false,
    },
    {
        field: "makingOrder",
        label: "Making Order",
        visible: false,
    },
    {
        field: "makingPrice",
        label: "Making Price",
        render: (value, field, row) => formatNumber(row.makingOrder?.price, 2)
    },
    {
        field: "hedgingPrice",
        label: "Hedging Price",
        render: (value, field, row) => formatNumber(row.hedgingOrder?.price, 0)
    },
    {
        field: "hedfingExPrice",
        label: "Hedging Ex Price",
        render: (value, field, row) => formatNumber(row.hedgingExOrder?.price, 2)
    },
    {
        field: "closingPrice",
        label: "Closing Price",
        render: (value, field, row) => formatNumber(row.closingOrder?.price, 2)
    },
    {
        field: "closingPriceSl",
        label: "Closing Price SL",
        render: (value, field, row) => formatNumber(row.closingOrder?.price * row.midRate, 2)
    },
    {
        field: "clearingPrice",
        label: "Clearing Price",
        render: (value, field, row) => formatNumber(row.clearingOrder?.price, 0)
    },
    {
        field: "clearingExPrice",
        label: "Clearing Ex Price",
        render: (value, field, row) => formatNumber(row.clearingExOrder?.price, 2)
    },
    {
        field: "makingQty",
        label: "Making Qty",
        render: (value, field, row) => formatNumber(row.makingOrder?.cumExecQty, 2)
    },
    {
        field: "makingValue",
        label: "Making Value",
        render: (value, field, row) => formatNumber(row.makingOrder?.cumExecValue, 3)
    },
    {
        field: "makingFee",
        label: "Making Fee",
        render: (value, field, row) => formatNumber(row.makingOrder?.cumExecFee, 3)
    },
    {
        field: "makingTime",
        label: "Making Time",
        render: (value, field, row) => {
            if (!row.makingOrder) {
                return "";
            }
            const time = new Date(Number(row.makingOrder?.updatedTime));
            return moment(time).tz("Asia/Seoul").format("'YY-MM-DD HH:mm:ss");
        }
    },
    // const { price: hedgePrice, executed_funds: hedgingValue, executed_volume: hedgeVolume, paid_fee: hedgeFee } = hedgingOrder;
    {
        field: "hedgingOrderId",
        label: "Hedging Order ID",
        visible: false,
    },
    {
        field: "hedgingOrder",
        visible: false,
    },
    {
        field: "hedgingQty",
        label: "Hedging Qty",
        render: (value, field, row) => formatNumber(row.hedgingOrder?.executed_volume, 3)
    },
    {
        field: "hedgingValue",
        label: "Hedging Value",
        render: (value, field, row) => formatNumber(row.hedgingOrder?.executed_funds, 0)
    },
    {
        field: "hedgingFee",
        label: "Hedging Fee",
        render: (value, field, row) => formatNumber(row.hedgingOrder?.paid_fee, 0)
    },
    {
        field: "hedgingTime",
        label: "Hedging Time",
        render: (value, field, row) => {
            const time = new Date(row.hedgingOrder?.created_at);
            return moment(time).tz("Asia/Seoul").format("'YY-MM-DD HH:mm:ss");
        }
    },
    {
        field: "hedgingExOrderId",
        label: "Hedging Ex Order ID",
        visible: false,
    },
    {
        field: "hedgingExOrder",
        label: "Hedging Ex Order",
        visible: false,
    },
    {
        field: "hedgingExQty",
        label: "Hedging Ex Qty",
        render: (value, field, row) => formatNumber(row.hedgingExOrder?.executed_volume, 3)
    },
    {
        field: "hedgingExValue",
        label: "Hedging Ex Value",
        render: (value, field, row) => formatNumber(row.hedgingExOrder?.executed_funds, 0)
    },
    {
        field: "hedgingExFee",
        label: "Hedging Ex Fee",
        render: (value, field, row) => formatNumber(row.hedgingExOrder?.paid_fee, 0)
    },
    {
        field: "hedgingExTime",
        label: "Hedging Ex Time",
        render: (value, field, row) => {
            const time = new Date(row.hedgingExOrder?.created_at);
            return moment(time).tz("Asia/Seoul").format("'YY-MM-DD HH:mm:ss");
        }
    },
    {
        field: "closingOrderId",
        label: "Closing Order ID",
        visible: false,
    },
    {
        field: "closingOrder",
        label: "Closing Order",
        visible: false,
    },

    {
        field: "closingQty",
        label: "Closing Qty",
        render: (value, field, row) => formatNumber(row.closingOrder?.cumExecQty, 3)
    },
    {
        field: "closingValue",
        label: "Closing Value",
        render: (value, field, row) => formatNumber(row.closingOrder?.cumExecValue, 3)
    },
    {
        field: "closingFee",
        label: "Closing Fee",
        render: (value, field, row) => formatNumber(row.closingOrder?.cumExecFee, 3)
    },
    {
        field: "closingTime",
        label: "Closing Time",
        render: (value, field, row) => {
            if (!row.closingOrder) {
                return "";
            }
            const time = new Date(Number(row.closingOrder?.updatedTime));
            return moment(time).tz("Asia/Seoul").format("'YY-MM-DD HH:mm:ss");
        }
    },
    {
        field: "clearingOrderId",
        label: "Clearing Order ID",
        visible: false,
    },
    {
        field: "clearingOrder",
        label: "Clearing Order",
        visible: false,
    },
    {
        field: "clearingQty",
        label: "Clearing Qty",
        render: (value, field, row) => formatNumber(row.clearingOrder?.executed_volume, 3)
    },
    {
        field: "clearingValue",
        label: "Clearing Value",
        render: (value, field, row) => formatNumber(row.clearingOrder?.executed_funds, 0)
    },
    {
        field: "clearingFee",
        label: "Clearing Fee",
        render: (value, field, row) => formatNumber(row.clearingOrder?.paid_fee, 0)
    },
    {
        field: "clearingTime",
        label: "Clearing Time",
        render: (value, field, row) => {
            if (!row.clearingOrder) {
                return "";
            }
            const time = new Date(row.clearingOrder?.created_at);
            return moment(time).tz("Asia/Seoul").format("'YY-MM-DD HH:mm:ss");
        }
    },
]

export const ratesTableColumns: Column[] = [
    {
        field: "asset",
        label: "Asset",
    },
    {
        field: "bid",
        label: "Bid",
        render: (value, field, row) => formatNumber(value, 2)
    },
    {
        field: "mid",
        label: "Mid",
        render: (value, field, row) => formatNumber(value, 2)
    },
    {
        field: "ask",
        label: "Ask",
        render: (value, field, row) => formatNumber(value, 2)
    }
]