import { formatNumber } from "components/Financial/Depth";
import { Column } from "components/Table/Simple";
import { formatDateTime } from "components/Table/Simple/presets";

/*
_id
strategyId
tierId
makingOrderId
hedgingOrderId
closingOrderId
clearingOrderId
makingOrder
hedgingOrder
clearingOrder
closingOrder
makingPrice
closingPrice
hedgePrice
clearingPrice
hedgingRate
clearingRate
krwProfit
usdtProfit
*/
export const columns: Column[] = [
    {
        label: "ID",
        field: "_id",
        visible: false,
    },
    {
        label: "Strategy ID",
        field: "strategyId",
    },
    {
        label: "Tier ID",
        field: "tierId",
        visible: false,
    },
    // {
    //     label: "KRW Profit",
    //     field: "krwProfit",
    //     render: (value) => `₩${formatNumber(value, 0)}`,
    // },
    // {
    //     label: "USDT Profit",
    //     field: "usdtProfit",
    //     render: (value, field, row) => {
    //         const clearingRate = row.clearingRate;
    //         return `$${formatNumber(value, 3)}(₩${formatNumber(value * clearingRate, 0)})`
    //     }
    // },
    {
        label: "Final Profit",
        field: "finalProfit",
        render: (value) => `₩${formatNumber(value, 0)}`,
    },
    {
        label: "Making Profit",
        field: "makingProfit",
        render: (value) => `₩${formatNumber(value, 0)}`,
    },
    {
        label: "Closing Profit",
        field: "closingProfit",
        render: (value) => `₩${formatNumber(value, 0)}`,
    },
    // {
    //     label: "Final Profit Calc",
    //     field: "finalProfitCalc",
    //     render: (value, field, row) => {
    //         const { makingProfit, closingProfit } = row;
    //         return `₩${formatNumber(makingProfit + closingProfit, 0)}`
    //     }
    // },
    // {
    //     label: "Making Profit Calc",
    //     field: "makingProfitCalc",
    //     render: (value, field, row) => {
    //         if (!row.makingOrder || !row.hedgingOrder || !row.hedgingExOrder) {
    //             return 0;
    //         }
    //         const { executed_funds: hedgingValue, paid_fee: hedgingFee } = row.hedgingOrder;
    //         const { executed_funds: hedgingExValue, paid_fee: hedgingExFee, price: hedgingExPrice } = row.hedgingExOrder;
    //         const { cumExecFee: makingFee } = row.makingOrder;
    //         let makingProfit = 0;
    //         if (row.tierId.startsWith("L")) {
    //             makingProfit = hedgingValue - hedgingExValue - hedgingFee - hedgingExFee - makingFee * hedgingExPrice;
    //         } else if (row.tierId.startsWith("S")) {
    //             makingProfit = hedgingExValue - hedgingValue - hedgingFee - hedgingExFee - makingFee * hedgingExPrice;
    //         }
    //         return formatNumber(makingProfit, 0);
    //     }
    // },
    // {
    //     label: "Closing Profit Calc",
    //     field: "closingProfitCalc",
    //     render: (value, field, row) => {
    //         if (!row.closingOrder || !row.clearingOrder || !row.clearingExOrder) {
    //             return 0;
    //         }
    //         const { executed_funds: clearingValue, paid_fee: clearingFee } = row.clearingOrder;
    //         const { executed_funds: clearingExValue, paid_fee: clearingExFee, price: clearingExPrice } = row.clearingExOrder;
    //         const { cumExecFee: closingFee } = row.closingOrder;
    //         let closingProfit = 0;
    //         if (row.tierId.startsWith("L")) {
    //             closingProfit = clearingExValue - clearingValue - clearingFee - clearingExFee - closingFee * clearingExPrice;
    //         } else if (row.tierId.startsWith("S")) {
    //             closingProfit = clearingValue - clearingExValue - clearingFee - clearingExFee - closingFee * clearingExPrice;
    //         }
    //         return formatNumber(closingProfit, 0);
    //     }
    // },
    {
        label: "Created At",
        field: "createdAt",
        render: formatDateTime
    },
    {
        label: "Making Order ID",
        field: "makingOrderId",
        visible: false,
    },
    {
        label: "Hedging Order ID",
        field: "hedgingOrderId",
        visible: false,
    },
    {
        label: "Closing Order ID",
        field: "closingOrderId",
        visible: false,
    },
    {
        label: "Clearing Order ID",
        field: "clearingOrderId",
        visible: false,
    },
    {
        label: "Making Order",
        field: "makingOrder",
        visible: false,
    },
    {
        label: "Hedging Order",
        field: "hedgingOrder",
        visible: false,
    },
    {
        label: "Clearing Order",
        field: "clearingOrder",
        visible: false,
    },
    {
        label: "Closing Order",
        field: "closingOrder",
        visible: false,
    },
    {
        label: "Making Price",
        field: "makingPrice",
        render: (value) => formatNumber(value, 2),
    },
    {
        label: "Closing Price",
        field: "closingPrice",
        render: (value) => formatNumber(value, 2),
    },
    {
        label: "Hedge Price",
        field: "hedgePrice",
        render: (value) => formatNumber(value, 0),
    },
    {
        label: "Clearing Price",
        field: "clearingPrice",
        render: (value) => formatNumber(value, 0),
    },
    {
        label: "Hedging Rate",
        field: "hedgingRate",
        render: (value) => formatNumber(value, 2),
    },
    {
        label: "Clearing Rate",
        field: "clearingRate",
        render: (value) => formatNumber(value, 2),
    },
    {
        label: "Making Value",
        field: "makingValue",
        render: (value, field, row) => {
            return row?.makingOrder?.cumExecValue
        }
    },
    {
        label: "Hedging Value",
        field: "hedgingValue",
        render: (value, field, row) => {
            return row?.hedgingOrder?.executed_funds
        }
    },
    {
        label: "Hedging Ex Value",
        field: "hedgingExValue",
        render: (value, field, row) => {
            return row?.hedgingExOrder?.executed_funds
        }
    },
    {
        label: "Hedging Ex Qty",
        field: "hedgingExQty",
        render: (value, field, row) => {
            return row?.hedgingExOrder?.executed_volume
        }
    },
    {
        label: "Closing Value",
        field: "closingValue",
        render: (value, field, row) => {
            return row?.closingOrder?.cumExecValue
        }
    },
    {
        label: "Clearing Value",
        field: "clearingValue",
        render: (value, field, row) => {
            return row?.clearingOrder?.executed_funds
        }
    },
    {
        label: "Clearing Ex Value",
        field: "clearingExValue",
        render: (value, field, row) => {
            return row?.clearingExOrder?.executed_funds
        }
    },
    {
        label: "Clearing Ex Qty",
        field: "clearingExQty",
        render: (value, field, row) => {
            return row?.clearingExOrder?.executed_volume
        }
    },

]

/*
{
    "symbol" : "AAVEUSDT",
    "orderType" : "Limit",
    "orderLinkId" : "L1_AAVE_ac05f4e12_04b0",
    "slLimitPrice" : "0",
    "orderId" : "2c4b3115-d4cf-41d3-8ada-3b12fc17f624",
    "cancelType" : "UNKNOWN",
    "avgPrice" : "351.63",
    "stopOrderType" : "",
    "lastPriceOnCreated" : "351.96",
    "orderStatus" : "Filled",
    "createType" : "CreateByUser",
    "takeProfit" : "",
    "cumExecValue" : "105.489",
    "tpslMode" : "",
    "smpType" : "None",
    "triggerDirection" : 0,
    "blockTradeId" : "",
    "rejectReason" : "EC_NoError",
    "isLeverage" : "",
    "price" : "351.63",
    "orderIv" : "",
    "createdTime" : "1737700563591",
    "tpTriggerBy" : "",
    "positionIdx" : 1,
    "timeInForce" : "PostOnly",
    "leavesValue" : "0",
    "updatedTime" : "1737701731600",
    "side" : "Buy",
    "smpGroup" : 0,
    "triggerPrice" : "",
    "tpLimitPrice" : "0",
    "cumExecFee" : "0.0210978",
    "slTriggerBy" : "",
    "leavesQty" : "0",
    "closeOnTrigger" : false,
    "placeType" : "",
    "cumExecQty" : "0.3",
    "reduceOnly" : false,
    "qty" : "0.3",
    "stopLoss" : "",
    "smpOrderId" : "",
    "triggerBy" : ""
}
*/

/*
{
    "uuid" : "71f0b158-c5c9-4215-8846-1a8e7ded459f",
    "side" : "ask",
    "ord_type" : "limit",
    "price" : "525000",
    "state" : "done",
    "market" : "KRW-AAVE",
    "created_at" : "2025-01-24T15:55:33+09:00",
    "volume" : "0.3",
    "remaining_volume" : "0",
    "reserved_fee" : "0",
    "remaining_fee" : "0",
    "paid_fee" : "78.75",
    "locked" : "0",
    "executed_volume" : "0.3",
    "executed_funds" : "157500",
    "trades_count" : 1,
    "time_in_force" : "fok",
    "identifier" : "L1_AAVE_ac05f4e12_9e68"
}
*/