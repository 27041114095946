export const SINGLETON_KEY = Symbol();

export type Singleton<T extends new (...args: any[]) => any> = T & {
    [SINGLETON_KEY]?: Map<string, T extends new (...args: any[]) => infer I ? I : never>;
};

export const Singleton = <T extends new (...args: any[]) => any>(type: T) =>
    new Proxy(type, {
        construct(target: Singleton<T>, argsList, newTarget) {
            if (target.prototype !== newTarget.prototype) {
                return Reflect.construct(target, argsList, newTarget);
            }

            const argsKey = JSON.stringify(argsList);

            if (!target[SINGLETON_KEY]) {
                target[SINGLETON_KEY] = new Map();
            }

            if (!target[SINGLETON_KEY]?.has(argsKey)) {
                const instance = Reflect.construct(target, argsList, newTarget);
                target[SINGLETON_KEY]?.set(argsKey, instance);
            }

            return target[SINGLETON_KEY]?.get(argsKey);
        },
    });
