import Card from "components/Card";
import PageTitle from "components/Title/Page";
import React, { useEffect, useState } from "react";
import SimpleTable from "components/Table/Simple";
import axios from "axios";
import { columns } from "./config";

const StatisticsPage = () => {
    const [tableData, setTableData] = useState(null);
    useEffect(()=>{
        fetchData();
    }, [])

    const fetchData = async ()=>{
        try{
            const ret = await axios.get("https://rest.cefimix.com/stat/profit");
            setTableData(ret.data);
        }catch(e){

        }
    }
    return (
        <Card>
            <div className="p-3">
                <PageTitle>Statistics</PageTitle>
                <SimpleTable
                    data={tableData}
                    columns={columns}
                />
            </div>
        </Card>
    )
}

export default StatisticsPage;
