import exchangeConfigs from './config';

export default class ExchangeWsClient {
    wsClient = null;
    onMsgReceived = (obj) => { }
    reconnectTimeout = 2000; // 재연결 시도 간격 (ms)
    isClosing = false; // 수동으로 연결 종료 여부를 추적

    constructor(onMsgReceived = (obj) => { }) {
        this.onMsgReceived = onMsgReceived
    }

    startWsClient = (exchange, symbol, channel: "depth" | "trade" = "depth") => { // depth || trade
        const wsClient = new WebSocket(exchangeConfigs[exchange].wsEndpoint);
        wsClient.binaryType = 'arraybuffer';// for upbit blob
        this.isClosing = false;
        wsClient.onopen = () => {
            const connectedMsg = `[Exchange Public Ws] Connected`;
            console.log(connectedMsg);
            let subscribeMsg = {};
            if (channel === "depth") {
                subscribeMsg = exchangeConfigs[exchange].getDepthSubscribeMsg(symbol);
            } else if (channel === "trade") {
                subscribeMsg = exchangeConfigs[exchange].getTradeSubscribeMsg(symbol);
            }
            wsClient.send(JSON.stringify(subscribeMsg));
        }

        wsClient.onclose = () => {
            const closeMsg = `[Exchange Public Ws] Closed`;
            console.log(closeMsg);
            // onClosed();
            if (!this.isClosing) {
                console.log(`[Exchange Public Ws] Reconnecting in ${this.reconnectTimeout / 1000} seconds...`);
                setTimeout(() => {
                    this.startWsClient(exchange, symbol, channel);
                }, this.reconnectTimeout);
            }

        }

        wsClient.onmessage = (event) => {
            this.onMsgReceived(event);
        }

        this.wsClient = wsClient;
    }

    closeWsClient = () => {
        this.isClosing = true;
        this.wsClient.close()
    }

}